import React, { Component } from 'react';
import axios from 'axios';
import EngForm from './component/EngForm'
import ArForm from './component/ArForm'
import { Route, Routes, HashRouter as Router } from 'react-router-dom';
import './css/font-awesome.css';
import './css/bootstrap.min.css';
import './css/AppStyle.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: this.initialValues(),
      formErrors: {},
      isSubmit: false,
    };
  }

  initialValues = () => {
    return {
      username: '',
      phone: '',
      city: '',
      area: '',
      interestedIn: '',
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { isSubmit } = this.state;
    if (isSubmit) {
      console.log(this.state.formValues); // Debugging purposes
    }
  }

  validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = 'Username is required!';
      console.log(values.username);
    }
    if (!values.phone) {
      errors.phone = 'Phone is required';
    } else if (!/^\d{10}$/.test(values.phone)) {
      errors.phone = 'Phone must be a 10-digit number';
    }
    return errors;
  };

  handleUserNameChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, username: event.target.value },
    });
  };

  handleUserPhoneChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, phone: event.target.value },
    });
  };

  handleUserCityChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, city: event.target.value },
    });
  };

  handleUserAreaChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, area: event.target.value },
    });
  };

  handleUserInterestedInChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, interestedIn: event.target.value },
    });
  };

  //API and validtion 
  handleSubmit = (e) => {
    e.preventDefault();
    const { formValues } = this.state;
    const formErrors = this.validate(formValues);
    this.setState({ formErrors, isSubmit: true });

    if (Object.keys(formErrors).length === 0) {
      axios
        .post('https://prod-154.westeurope.logic.azure.com:443/workflows/ea27a6262eda48b59295c67ab1d02f20/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=unJaBxmSh3Q1TQZ89dJ-nsk43mGh5k3i_Y4udDpbD40', {
          Name: formValues.username,
          Mobile: parseInt(formValues.phone),
          City: formValues.city,
          Area: formValues.area,
          InterestedIn: formValues.interestedIn,
        })
        .then((res) => {
          console.log(res);
          console.log(res.data);

        })
        .catch((error) => {
          console.error('Error making API call:', error);
          console.log(error.response.data); // Log the response data
          console.log(error.response.status); // Log the response status code
        }
        );
    }
  };

  render() {
    //const { formValues, formErrors } = this.state;

    return (
<div>

     <Router>
          <Routes>    
            <Route path="/" element={<EngForm />}></Route>
            <Route path="/ArabbicForm" element={<ArForm />}></Route>
            <Route path="/successfulaPage" element={<successfulaPage />}></Route>

          </Routes>
        </Router>


</div>

);
  }
}

export default App;