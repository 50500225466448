import React, { Component } from 'react';
import axios from 'axios';
import FanarLogo from '../imgs/alfanar-logo.svg';
import imagebanar from '../imgs/main-banner.jpg';

class EngForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formValues: this.initialValues(),
      formErrors: {},
      isSubmit: false,
      isSuccess: false,
      FormHidden: true
    };
  }

  initialValues = () => {
    return {
      username: '',
      phone: '',
      city: '',
      area: '',
      interestedIn: '',
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { isSubmit } = this.state;
    if (isSubmit) {
      console.log(this.state.formValues); // Debugging purposes
    }
  }

  validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = 'User name is required';
    } else if (values.username.length < 3) {
      errors.username = 'Username must be at least 3 characters long';
    }
    if (!values.phone) {
      errors.phone = 'Phone is required';
    } else if (!/^\d{10}$/.test(values.phone)) {
      errors.phone = 'Phone must be a 10-digit number';
    }
    if (!values.city) {
      errors.city = 'City is required';
    }

    if (values.interestedIn.length > 120) {
      errors.interestedIn = 'Message must not be more than 120 characters';
    }
    return errors;
  };

  handleValidation() {
    const { formValues } = this.state;
    const errors = this.validate(formValues);
    this.setState({ formErrors: errors });
    return Object.keys(errors).length === 0;
  }

  handleUserNameChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, username: event.target.value },
    });
  };

  handleUserPhoneChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, phone: event.target.value },
    });
  };

  handleUserCityChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, city: event.target.value },
    });
  };
  handleUserAreaChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, area: event.target.value },
    });
  };

  handleUserInterestedInChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, interestedIn: event.target.value },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { formValues } = this.state;

    if (this.handleValidation()) {
      axios
        .post('https://prod-225.westeurope.logic.azure.com/workflows/249cda1af2b947e99b702e0ada6b8821/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=KljFd3IrHEFNty9VO2YKv0r6_o_vn7DXdRK1oKK_tgM', {
          Name: formValues.username,
          Mobile: parseInt(formValues.phone),
          City: formValues.city,
          Area: formValues.area,
          InterestedIn: formValues.interestedIn,
        })
        .then((res) => {
          console.log(res);
          console.log(res.data);
          this.setState({ isSuccess: true,FormHidden:false });
          this.resetForm();
        })
        .catch((error) => {
          console.error('Error making API call:', error);
          console.log(error.response.data); // Log the response data
          console.log(error.response.status); // Log the response status code
        });
    } else {
      console.log('Form has errors.');
    }
  };

  resetForm = () => {
    this.setState({ formValues: this.initialValues(), isSubmit: false, });
  };

  initialValues = () => {
    return {
      username: '',
      phone: '',
      city: '',
      area: '',
      interestedIn: '',
    };
  };
  render() {
    const { formValues, formErrors, isSuccess ,FormHidden} = this.state;

    return (
      <div className="container no-padding" >
        <a className="arabic_lang" href="#/ArabbicForm">
          <i className="fa fa-globe" />
          <p style={{ color: 'white' }}>AR</p>
        </a>
        <div className="logo" >
          <img className="logo-size" src={FanarLogo} alt='Alfanar' />
        </div>
        <img src={imagebanar} alt='Alfanar' style={{ width: '100%' }} />
        <div className="col-sm-12  form-body without-side">
          <div className="padder" style={{ borderBottom: '8px solid #f3f3f3', textAlign: 'center' }}>
            <h3>Discover alfanar Switches & Sockets</h3>
<p>Fantastic designs in various colors and shapes.</p>
 <p>High quality and a genuine guarantee make it a reliable choice.</p>
  <p>Complete solutions of Switches &amp; Sockets</p> 
  <p>Free maintenance service to ensure continuity.</p> 
  <p>Made in Saudi Arabia</p>
            <a href="https://www.alfanar.com/Electrical-switches-sockets" rel="noopener noreferrer" target="_blank"><button className="btn btn-info">Know more...</button></a>

          </div>
          <div className="form-holder">
            <div className="form-content">
              <div className="form-items">
                {isSuccess && (
                  <div>
                    <h2 style={{ color: 'green', textAlign: 'center' }}>Thank you</h2>
                    <p>Our representative will contact you very soon.</p>
                  </div>
                )}
              {FormHidden && (
                <div> 
                <h3>Get In Touch</h3>
                <p>
                  For any orders or inquiry please fill the below and we will contact you.
                </p>
                <form className='flexleft' onSubmit={this.handleSubmit}>
                  <label>Name</label>
                  <input
                    type="text"
                    name="username"
                    placeholder="User name"
                    value={formValues.username}
                    onChange={this.handleUserNameChange} />
                  {formErrors.username && <p style={{ color: 'red' }}>{formErrors.username}</p>}
                  <label>Mobile Number</label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Mobile Number"
                    value={formValues.phone}
                    onChange={this.handleUserPhoneChange} />
                  {formErrors.phone && <p style={{ color: 'red' }}>{formErrors.phone}</p>}

                  <label>City</label>
                  <select
                    name="city"
                    placeholder="City"
                    value={formValues.city}
                    onChange={this.handleUserCityChange}
                  >
                    <option value>Select City</option>
                    <option value="Ry">Riyadh</option>
                    <option value="JED">Jeddah</option>
                    <option value="Mecca">Mecca</option>
                    <option value="Dm">Dammam</option>
                    <option value="Al Hufuf">Al Hufuf</option>
                    <option value="Hafr al Batin">Hafr al Batin</option>
                    <option value="Buraydah">Buraydah</option>
                    <option value="Tabuk">Tabuk</option>
                    <option value="Al Taif">Al Taif</option>
                    <option value="Hail">Hail</option>
                    <option value="Al Mubarraz">Al Mubarraz</option>
                    <option value="Yanbu">Yanbu</option>
                    <option value="KHM">Khamis Mushayt</option>
                    <option value="Abha">Abha</option>
                    <option value="Sakaka">Sakaka</option>
                    <option value="Al Khubar">Al Khubar</option>
                    <option value="Medina">Medina</option>
                    <option value="Al Jubayl">Al Jubayl</option>
                    <option value="Al Qunfudhah">Al Qunfudhah</option>
                    <option value="Al Hillah">Al Hillah</option>
                    <option value="Al Kharj">Al Kharj</option>
                    <option value="Rafha">Rafha</option>
                    <option value="Najran">Najran</option>
                    <option value="Skaka">Skaka</option>
                    <option value="Sabya">Sabya</option>
                    <option value="Al Khubar">Al Khubar</option>
                    <option value="Qal‘at Bishah">Qal‘at Bishah</option>
                    <option value="Unayzah">Unayzah</option>
                    <option value="Ras Tanura">Ras Tanura</option>
                    <option value="Al Hawiyah">Al Hawiyah</option>
                    <option value="Al Qurayyat">Al Qurayyat</option>
                    <option value="Ar Rass">Ar Rass</option>
                    <option value="Jazan">Jazan</option>
                    <option value="Az Zulfi">Az Zulfi</option>
                    <option value="Sayhat">Sayhat</option>
                    <option value="Harad">Harad</option>
                    <option value="Al Ahad al Masarihah">Al Ahad al Masarihah</option>
                    <option value="Turayf">Turayf</option>
                    <option value="Sharurah">Sharurah</option>
                    <option value="Rafḩa">Rafḩa</option>
                    <option value="Najran">Najran</option>
                    <option value="Al Lith">Al Lith</option>
                    <option value="Ad Darb">Ad Darb</option>
                    <option value="Ra’s al Khafji">Ra’s al Khafji</option>
                    <option value="Badr Hunayn">Badr Hunayn</option>
                    <option value="Khulays">Khulays</option>
                    <option value="An Nimas">An Nimas</option>
                    <option value="Al Majaridah">Al Majaridah</option>
                    <option value="Al Wajh">Al Wajh</option>
                    <option value="Al Midhnab">Al Midhnab</option>
                    <option value="Abqaiq">Abqaiq</option>
                    <option value="Al ‘Aqiq">Al ‘Aqiq</option>
                    <option value="Ḑulay‘ Rashid">Ḑulay‘ Rashid</option>
                    <option value="Shaqra">Shaqra</option>
                    <option value="Al Mindak">Al Mindak</option>
                    <option value="Dhahran">Dhahran</option>
                    <option value="Al Aydabi">Al Aydabi</option>
                    <option value="Qara">Qara</option>
                    <option value="Ash Shinan">Ash Shinan</option>
                    <option value="Arar">Arar</option>
                    <option value="Haql">Haql</option>
                    <option value="Duba">Duba</option>
                    <option value="Al Baḩah">Al Baḩah</option>
                  </select>
                  {formErrors.city && <p style={{ color: 'red' }}>{formErrors.city}</p>}

                    <div>
                      <label>Area</label>
                      <select
                        name="area"
                        placeholder="Area"
                        value={formValues.area}
                        onChange={this.handleUserAreaChange}
                      >
                        <option value>Select Area</option>
                        <option value="Turaif">Turaif</option>
<option value="Domet Al-Jandal">Domet Al-Jandal</option>
<option value="Tubarjal">Tubarjal</option>
<option value="Al-Qaisuma">Al-Qaisuma</option>
<option value="Aissaouia">Aissaouia</option>
<option value="Al-Uwayqilah">Al-Uwayqilah</option>
<option value="Al-Wajh">Al-Wajh</option>
<option value="Amlaj">Amlaj</option>
<option value="Tayma">Tayma</option>
<option value="Haql">Haql</option>
<option value="Bir Ben Hirmas">Bir Ben Hirmas</option>
<option value="Rafhaa">Rafhaa</option>
<option value="Suwayr">Suwayr</option>
<option value="Al-Hudood Al-Shamalya">Al-Hudood Al-Shamalya</option>
<option value="Skaka">Skaka</option>
<option value="Alquriat">Alquriat</option>
<option value="Araar">Araar</option>
<option value="Al-Hufuf">Al-Hufuf</option>
<option value="Baqaiq">Baqaiq</option>
<option value="Judah">Judah</option>
<option value="Ras Tanura">Ras Tanura</option>
<option value="Khafji">Khafji</option>
<option value="Al-Oyoan">Al-Oyoan</option>
<option value="Al-Olayaa">Al-Olayaa</option>
<option value="Mulayjah">Mulayjah</option>
<option value="Assrrar">Assrrar</option>
<option value="Al-Mubarez">Al-Mubarez</option>
<option value="aja">aja</option>
<option value="al masif">al masif</option>
<option value="al muntazah al gharbi">al muntazah al gharbi</option>
<option value="al muntazah ash sharqi">al muntazah ash sharqi</option>
<option value="al muzaabar">al muzaabar</option>
<option value="al wusayta">al wusayta</option>
<option value="an naqrah">an naqrah</option>
<option value="an nasiyah">an nasiyah</option>
<option value="as samra">as samra</option>
<option value="as suwayfilah">as suwayfilah</option>
<option value="az zahra">az zahra</option>
<option value="airport">airport</option>
<option value="az zibarah">az zibarah</option>
<option value="barzan">barzan</option>
<option value="hadri al bilad">hadri al bilad</option>
<option value="hail regional airport">hail regional airport</option>
<option value="industrial area">industrial area</option>
<option value="labdah">labdah</option>
<option value="al mahattah">al mahattah</option>
<option value="mughaydah">mughaydah</option>
<option value="sababah">sababah</option>
<option value="samah">samah</option>
<option value="al aziziyah">al aziziyah</option>
<option value="al badnah">al badnah</option>
<option value="al buhayrah">al buhayrah</option>
<option value="al khamashiyah">al khamashiyah</option>
<option value="al khuraymi">al khuraymi</option>
<option value="al khuzama">al khuzama</option>
<option value="Al Laqitah">Al Laqitah</option>
<option value="Qifar">Qifar</option>
<option value="Al Madain">Al Madain</option>
<option value="Dammam">Dammam</option>
<option value="al khalidiyah ash shamaliyah">al khalidiyah ash shamaliyah</option>
<option value="ad dabab">ad dabab</option>
<option value="ad danah">ad danah</option>
<option value="ad dawasir">ad dawasir</option>
<option value="al adamah">al adamah</option>
<option value="al amal">al amal</option>
<option value="al amamrah">al amamrah</option>
<option value="al amanah">al amanah</option>
<option value="al amir muhammed ibn saud">Al Amir Muhammed Ibn Saud</option>
<option value="al anud">Al Anud</option>
<option value="al anwar">Al Anwar</option>
<option value="al athir">Al Athir</option>
<option value="al aziziyah">Al Aziziyah</option>
<option value="al badi">Al Badi</option>
<option value="al badiyah">Al Badiyah</option>
<option value="al basatin">Al Basatin</option>
<option value="al buhayrah">Al Buhayrah</option>
<option value="al faiha">Al Faiha</option>
<option value="al faisaliyah">Al Faisaliyah</option>
<option value="al fanar">Al Fanar</option>
<option value="al firdaws">Al Firdaws</option>
<option value="al fursan">Al Fursan</option>
<option value="al hadabah">Al Hadabah</option>
<option value="al hamra">Al Hamra</option>
<option value="al hussam">Al Hussam</option>
<option value="al iskan">Al Iskan</option>
<option value="al itisalat">Al Itisalat</option>
<option value="al jalawiyah">Al Jalawiyah</option>
<option value="al jamiyin">Al Jamiyin</option>
<option value="al jawharah">Al Jawharah</option>
<option value="al khalidiyah al janubiyah">Al Khalidiyah Al Janubiyah</option>
<option value="al khalij">Al Khalij</option>
<option value="al kuthriah">Al Kuthriah</option>
<option value="al maha">Al Maha</option>
<option value="al manar">Al Manar</option>
<option value="al matar">Al Matar</option>
<option value="al mazruiyah">Al Mazruiyah</option>
<option value="al muhammadiyah">Al Muhammadiyah</option>
<option value="al muntazah">Al Muntazah</option>
<option value="al muraikabat">Al Muraikabat</option>
<option value="al qadisiyah">Al Qadisiyah</option>
<option value="al qazaz">Al Qazaz</option>
<option value="al wahah">Al Wahah</option>
<option value="an nada">An Nada</option>
<option value="an nahdah">An Nahdah</option>
<option value="an nakhil">An Nakhil</option>
<option value="an nasim">An Nasim</option>
<option value="an nasriyah">An Nasriyah</option>
<option value="an nawras">An Nawras</option>
<option value="an nur">An Nur</option>
<option value="an nuzhah">An Nuzhah</option>
<option value="ar rabi">Ar Rabi</option>
<option value="ar rabiyah">Ar Rabiyah</option>
<option value="ar rakah ash shamaliyah">Ar Rakah Ash Shamaliyah</option>
<option value="ar rawdah">Ar Rawdah</option>
<option value="ar rayyan">Ar Rayyan</option>
<option value="as sadafah">As Sadafah</option>
<option value="as safa">As Safa</option>
<option value="as saif">As Saif</option>
<option value="as salam">As Salam</option>
<option value="as suq">As Suq</option>
<option value="ash sharq">Ash Sharq</option>
<option value="ash shati al gharbi">Ash Shati Al Gharbi</option>
<option value="ash shati ash sharqi">Ash Shati Ash Sharqi</option>
<option value="ash shifa">Ash Shifa</option>
<option value="ash shulah">Ash Shulah</option>
<option value="at tubayshi">At Tubayshi</option>
<option value="az zuhur">Az Zuhur</option>
<option value="badr">Badr</option>
<option value="dahiyat al malik fahd">Dahiyat Al Malik Fahd</option>
<option value="ghirnatah">Ghirnatah</option>
<option value="ibn khaldun">Ibn Khaldun</option>
<option value="industrial">Industrial</option>
<option value="industrial area no1">Industrial Area No.1</option>
<option value="industrial city 2">Industrial City 2</option>
<option value="king abdul aziz seaport">King Abdul Aziz Seaport</option>
<option value="king fahd international airport">King Fahd International Airport</option>
<option value="king faysal university">King Faysal University</option>
<option value="madinat al umal">Madinat Al Umal</option>
<option value="qasr al khalij">Qasr Al Khalij</option>
<option value="taybah">Taybah</option>
<option value="uhud">Uhud</option>
<option value="al oroobh">Al Oroobh</option>
<option value="Dhahran">Dhahran</option>
<option value="Al-Khobar">Al-Khobar</option>
<option value="Al-Qatif">Al-Qatif</option>
<option value="Al-Jobail">Al-Jobail</option>
<option value="Al Ahsa">Al Ahsa</option>
<option value="agricultura area">Agricultura Area</option>
<option value="al jishah">Al Jishah</option>
<option value="al munayzilah">Al Munayzilah</option>
<option value="at tarf">At Tarf</option>
<option value="jwatha">Jwatha</option>
<option value="madinat al fdool">Madinat Al Fdool</option>
<option value="madinat al jafr">Madinat Al Jafr</option>
<option value="madinat al umran">Madinat Al Umran</option>
<option value="madinat al uyun">Madinat Al Uyun</option>
<option value="Hafr Al-Batin">Hafr Al-Batin</option>
<option value="ar rabi">Ar Rabi</option>
<option value="abu musa al ashari">Abu Musa Al Ashari</option>
<option value="al aziziyah">Al Aziziyah</option>
<option value="al baladiyah">Al Baladiyah</option>
<option value="al batin">Al Batin</option>
<option value="al faiha">Al Faiha</option>
<option value="al faisaliyah">Al Faisaliyah</option>
<option value="al iskan">Al Iskan</option>
<option value="al jamiah">Al Jamiah</option>
<option value="al khalidiyah">Al Khalidiyah</option>
<option value="al khalij">Al Khalij</option>
<option value="al manar">Al Manar</option>
<option value="al masif">Al Masif</option>
<option value="al muhammadiyah">Al Muhammadiyah</option>
<option value="al muruj">Al Muruj</option>
<option value="al wadi">Al Wadi</option>
<option value="al wahah">Al Wahah</option>
<option value="al wurud">Al Wurud</option>
<option value="al yarmuk">Al Yarmuk</option>
<option value="an nahdah">An Nahdah</option>
<option value="an nakhil">An Nakhil</option>
<option value="an nayfiyah">An Nayfiyah</option>
<option value="an nuzhah">An Nuzhah</option>
<option value="ar rabiyah">Ar Rabiyah</option>
<option value="ar rabwah">Ar Rabwah</option>
<option value="ar raid">Ar Raid</option>
<option value="ar rawdah">Ar Rawdah</option>
<option value="ar rayan">Ar Rayan</option>
<option value="as safaa">As Safaa</option>
<option value="as sinaiyah">As Sinaiyah</option>
<option value="as sulimaniyah">As Sulimaniyah</option>
<option value="ash shifa">Ash Shifa</option>
<option value="at talal">At Talal</option>
<option value="fulaij">Fulaij</option>
<option value="ghirnatah">Ghirnatah</option>
<option value="qurtubah">Qurtubah</option>
<option value="Hfr-batin">Hfr-batin</option>
<option value="AL-Amran">AL-Amran</option>
<option value="AL-Methnab">AL-Methnab</option>
<option value="Al-Jumum">Al-Jumum</option>
<option value="Yanbu Al-Nakhal">Yanbu Al-Nakhal</option>
<option value="Al-Ais">Al-Ais</option>
<option value="Al-Bayda">Al-Bayda</option>
<option value="Ashayrah">Ashayrah</option>
<option value="Bin Malik">Bin Malik</option>
<option value="Bin Saad">Bin Saad</option>
<option value="Maysaan">Maysaan</option>
<option value="Belqaren">Belqaren</option>
<option value="Ranyah">Ranyah</option>
<option value="Turubah">Turubah</option>
<option value="Al-Khurma">Al-Khurma</option>
<option value="Rabigh">Rabigh</option>
<option value="Al-Lith">Al-Lith</option>
<option value="Khaybar">Khaybar</option>
<option value="Mahd adh Dhahab">Mahd adh Dhahab</option>
<option value="Wadi Al-Foroa">Wadi Al-Foroa</option>
<option value="Al-Mojarada">Al-Mojarada</option>
<option value="Al-Qunfudhah">Al-Qunfudhah</option>
<option value="Khulais">Khulais</option>
<option value="Al-Qouz">Al-Qouz</option>
<option value="Al-Muwayh">Al-Muwayh</option>
<option value="Al-Kamil">Al-Kamil</option>
<option value="Thraiban">Thraiban</option>
<option value="Badr">Badr</option>
<option value="Al-Ula">Al-Ula</option>
<option value="Al-Hanakiyya">Al-Hanakiyya</option>
<option value="Al-Basheer">Al-Basheer</option>
<option value="Bareq">Bareq</option>
<option value="Baljurashi">Baljurashi</option>
<option value="Almandaq">Almandaq</option>
<option value="Al-Kalwa">Al-Kalwa</option>
<option value="Al-Makhwa">Al-Makhwa</option>
<option value="Al-Aqiq">Al-Aqiq</option>
<option value="Diba">Diba</option>
<option value="AL-Bidaa">AL-Bidaa</option>
<option value="Al Huwaya">Al Huwaya</option>
<option value="Al Sharafiah">Al Sharafiah</option>
<option value="Ateef">Ateef</option>
<option value="Al-Taif">Al-Taif</option>
<option value="umm al arad">Umm Al Arad</option>
<option value="al aqiq">Al Aqiq</option>
<option value="al aziziyah">Al Aziziyah</option>
<option value="al faisaliyah">Al Faisaliyah</option>
<option value="al khalidiyah">Al Khalidiyah</option>
<option value="al mathnah">Al Mathnah</option>
<option value="al qamariyah">Al Qamariyah</option>
<option value="al qutbiyah">Al Qutbiyah</option>
<option value="al washha">Al Washha</option>
<option value="an nuzhah">An Nuzhah</option>
<option value="ar rayan">Ar Rayan</option>
<option value="as sadad">As Sadad</option>
<option value="as salamah">As Salamah</option>
<option value="ash sharqiyah">Ash Sharqiyah</option>
<option value="ash shuhada al janubiyah">Ash Shuhada Al Janubiyah</option>
<option value="ash shuhada ash shamaliyah">Ash Shuhada Ash Shamaliyah</option>
<option value="awdah">Awdah</option>
<option value="central area">Central Area</option>
<option value="hawaya">Hawaya</option>
<option value="jabrah">Jabrah</option>
<option value="maashi">Maashi</option>
<option value="masarrah">Masarrah</option>
<option value="nakhb">Nakhb</option>
<option value="qurwa">Qurwa</option>
<option value="shihar">Shihar</option>
<option value="shubra">Shubra</option>
<option value="Al-Majmaah">Al-Majmaah</option>
<option value="Jeddah">Jeddah</option>
<option value="az zomorod">Az Zomorod</option>
<option value="abhur al janubiyah">Abhur Al Janubiyah</option>
<option value="abhur ash shamaliyah">Abhur Ash Shamaliyah</option>
<option value="abruq ar rughamah">Abrug Ar Rughamah</option>
<option value="ad dahiah">Ad Dahiah</option>
<option value="al adel">Al Adel</option>
<option value="al ajaweed">Al Ajaweed</option>
<option value="al ajwad">Al Ajwad</option>
<option value="al amir abdoulmajed">Al Amir Abdoulmajed</option>
<option value="al amir fawaz al janouby">Al Amir Fawaz Al Janouby</option>
<option value="al amir fawaz ash shamaly">Al Amir Fawaz Ash Shamaly</option>
<option value="al ammariyah">Al Ammariyah</option>
<option value="al amwaj">Al Amwaj</option>
<option value="al andalus">Al Andalus</option>
<option value="al asalah">Al Asalah</option>
<option value="al aziziyah">Al Aziziyah</option>
<option value="al baghdadiyah al gharbiyah">Al Baghdadiyah Al Gharbiyah</option>
<option value="al baghdadiyah ash sharqiyah">Al Baghdadiyah Ash Sharqiyah</option>
<option value="al balad">Al Balad</option>
<option value="al barakah">Al Barakah</option>
<option value="al basatin">Al Basatin</option>
<option value="al bashaer">Al Bashaer</option>
<option value="al bawadi">Al Bawadi</option>
<option value="al fadel">Al Fadel</option>
<option value="al fadeylah">Al Fadeylah</option>
<option value="al faiha">Al Faiha</option>
<option value="al faisaliyah">Al Faisaliyah</option>
<option value="al falah">Al Falah</option>
<option value="al farouk">Al Farouk</option>
<option value="al ferdous">Al Ferdous</option>
<option value="al frosyah">Al Frosyah</option>
<option value="al hada">Al Hada</option>
<option value="al hamadaniyah">Al Hamadaniyah</option>
<option value="al hamra">Al Hamra</option>
<option value="al hindawiyah">Al Hindawiyah</option>
<option value="al jamiah">Al Jamiah</option>
<option value="al jawharah">Al Jawharah</option>
<option value="al kandarah">Al Kandarah</option>
<option value="al karamah">Al Karamah</option>
<option value="al kawthar">Al Kawthar</option>
<option value="al khalidiyah">Al Khalidiyah</option>
<option value="al khomrah">Al Khomrah</option>
<option value="al loaloa">Al Loaloa</option>
<option value="al mahjar">Al Mahjar</option>
<option value="al manar">Al Manar</option>
<option value="al marwah">Al Marwah</option>
<option value="al masarah">Al Masarah</option>
<option value="al montazah">Al Montazah</option>
<option value="al moulysaa">Al Moulysaa</option>
<option value="al muhammadiyah">Al Muhammadiyah</option>
<option value="al murjan">Al Murjan</option>
<option value="al mutanazahat">Al Mutanazahat</option>
<option value="al qouzeen">Al Qouzeen</option>
<option value="al qryniah">Al Qryniah</option>
<option value="al quraiyat">Al Quraiyat</option>
<option value="al wadi">Al Wadi</option>
<option value="al wahah">Al Wahah</option>
<option value="al waziriyah">Al Waziriyah</option>
<option value="al wurud">Al Wurud</option>
<option value="al yaqoot">Al Yaqoot</option>
<option value="an nahdah">An Nahdah</option>
<option value="an naim">An Naim</option>
<option value="an nakhil">An Nakhil</option>
<option value="an nasim">An Nasim</option>
<option value="an nazlah al yamaniyah">An Nazlah Al Yamaniyah</option>
<option value="an nazlah ash sharqiyah">An Nazlah Ash Sharqiyah</option>
<option value="an nuzhah">An Nuzhah</option>
<option value="ar rabwah">Ar Rabwah</option>
<option value="ar rahmah">Ar Rahmah</option>
<option value="ar rahmanyah">Ar Rahmanyah</option>
<option value="ar rawabi">Ar Rawabi</option>
<option value="ar rawdah">Ar Rawdah</option>
<option value="ar rayaan">Ar Rayaan</option>
<option value="ar rihab">Ar Rihab</option>
<option value="ar ruwais">Ar Ruwais</option>
<option value="as sabil">As Sabil</option>
<option value="as safa">As Safa</option>
<option value="as sahifah">As Sahifah</option>
<option value="as sahil">As Sahil</option>
<option value="as salamah">As Salamah</option>
<option value="as salhiyah">As Salhiyah</option>
<option value="as samir">As Samir</option>
<option value="as sanabel">As Sanabel</option>
<option value="as sarawat">As Sarawat</option>
<option value="as sororyah">As Sororyah</option>
<option value="as sulaymaniyah">As Sulaymaniyah</option>
<option value="as swaryee">As Swaryee</option>
<option value="ash sharafiyah">Ash Sharafiyah</option>
<option value="ash shati">Ash Shati</option>
<option value="ash shefaa">Ash Shefaa</option>
<option value="ash sheraa">Ash Sheraa</option>
<option value="ash shrouk">Ash Shrouk</option>
<option value="at taawon">At Taawon</option>
<option value="at tadamon">At Tadamon</option>
<option value="ath thaalibah">Ath Thaalibah</option>
<option value="ath thaghr">Ath Thaghr</option>
<option value="az zahra">Az Zahra</option>
<option value="bahrah">Bahrah</option>
<option value="bani malik">Bani Malik</option>
<option value="bryman">Bryman</option>
<option value="ghulail">Ghulail</option>
<option value="industrial area">Industrial Area</option>
<option value="jeddah Islamic seaport">Jeddah Islamic Seaport</option>
<option value="king abdul aziz international airport">King Abdul Aziz International Airport</option>
<option value="king abdul aziz medical city">King Abdul Aziz Medical City</option>
<option value="king abdul aziz university">King Abdul Aziz University</option>
<option value="king faisal naval base">King Faisal Naval Base</option>
<option value="madain al fahd">Madain Al Fahd</option>
<option value="mishrifah">Mishrifah</option>
<option value="mraykh">Mraykh</option>
<option value="petromin">Petromin</option>
<option value="taibah">Taibah</option>
<option value="um asalam">Um Asalam</option>
<option value="um hableen">Um Hableen</option>
<option value="Makkah AL Mokarrama">Makkah AL Mokarrama</option>
<option value="al adl">Al Adl</option>
<option value="ad diyafah">Ad Diyafah</option>
<option value="ajyad">Ajyad</option>
<option value="al andalus">Al Andalus</option>
<option value="al awali">Al Awali</option>
<option value="al aziziyah">Al Aziziyah</option>
<option value="al bibyan">Al Bibyan</option>
<option value="al buhayrat">Al Buhayrat</option>
<option value="al hajlah">Al Hajlah</option>
<option value="al hamra umm al jud">Al Hamra Umm Al Jud</option>
<option value="al haram">Al Haram</option>
<option value="al hijrah">Al Hijrah</option>
<option value="al hindawiyah">Al Hindawiyah</option>
<option value="al hujun">Al Hujun</option>
<option value="al jamiah">Al Jamiah</option>
<option value="al jummayzah">Al Jummayzah</option>
<option value="al kakiyah">Al Kakiyah</option>
<option value="al khadra">Al Khadra</option>
<option value="al khalidiyah">Al Khalidiyah</option>
<option value="al khansa">Al Khansa</option>
<option value="al maabdah">Al Maabdah</option>
<option value="al mansur">Al Mansur</option>
<option value="al mashair">Al Mashair</option>
<option value="al misfalah">Al Misfalah</option>
<option value="al mursalat">Al Mursalat</option>
<option value="al qararah and an naqa">Al Qararah and An Naqa</option>
<option value="al ukayshiyah">Al Ukayshiyah</option>
<option value="al umrah al jadidah">Al Umrah Al Jadidah</option>
<option value="al usaylah">Al Usaylah</option>
<option value="al utaybiyah">Al Utaybiyah</option>
<option value="an nasim">An Nasim</option>
<option value="an nuzhah">An Nuzhah</option>
<option value="ar rashidiyah">Ar Rashidiyah</option>
<option value="ar rawabi">Ar Rawabi</option>
<option value="ar rawdah">Ar Rawdah</option>
<option value="ar rusayfah">Ar Rusayfah</option>
<option value="as salamah">As Salamah</option>
<option value="as sulimaniyah">As Sulimaniyah</option>
<option value="ash shawqiyah">Ash Shawqiyah</option>
<option value="ash shubaikah">Ash Shubaikah</option>
<option value="ash shuhada">Ash Shuhada</option>
<option value="asharai">Asharai</option>
<option value="at tandabawi">At Tandabawi</option>
<option value="at tanim">At Tanim</option>
<option value="at taqwa">At Taqwa</option>
<option value="at taysir">At Taysir</option>
<option value="az zahir">Az Zahir</option>
<option value="az zahra">Az Zahra</option>
<option value="batha quraysh">Batha Quraysh</option>
<option value="harat al bab and ash shamiyah">Harat Al Bab and Ash Shamiyah</option>
<option value="jabal an nur">Jabal An Nur</option>
<option value="jarham">Jarham</option>
<option value="jarwal">Jarwal</option>
<option value="king fahd">King Fahd</option>
<option value="kudy">Kudy</option>
<option value="ray zakhir">Ray Zakhir</option>
<option value="shaib amir and shaib ali">Shaib Amir and Shaib Ali</option>
<option value="sharai al mujahidin">Sharai Al Mujahidin</option>
<option value="wadi jalil">Wadi Jalil</option>
<option value="waly al ahd">Waly Al Ahd</option>
<option value="Al-Madinah Al-Monawarh">Al-Madinah Al-Monawarh</option>
<option value="Aqoul">Aqoul</option>
<option value="Shuran">Shuran</option>
<option value="Taibah">Taibah</option>
<option value="Al Jarf Algharbi">Al Jarf Algharbi</option>
<option value="Al jarf Al sharqi">Al Jarf Al Sharqi</option>
<option value="khakh">Khakh</option>
<option value="abu burayqa">Abu Burayqa</option>
<option value="abu kabir">Abu Kabir</option>
<option value="ad dar">Ad Dar</option>
<option value="ad difa">Ad Difa</option>
<option value="ad duwaikhilah">Ad Duwaikhilah</option>
<option value="ad duwaimah">Ad Duwaimah</option>
<option value="airport">Airport</option>
<option value="al anabis">Al Anabis</option>
<option value="al ariyd">Al Ariyd</option>
<option value="al aziziyah">Al Aziziyah</option>
<option value="al balqa">Al Balqa</option>
<option value="al barakah">Al Barakah</option>
<option value="al fath">Al Fath</option>
<option value="al ghabah">Al Ghabah</option>
<option value="al gharra">Al Gharra</option>
<option value="al hadiqah">Al Hadiqah</option>
<option value="al hadra">Al Hadra</option>
<option value="al hafya">Al Hafya</option>
<option value="al haram">Al Haram</option>
<option value="al ihn">Al Ihn</option>
<option value="al iskan">Al Iskan</option>
<option value="al jabirah">Al Jabirah</option>
<option value="al jamawat">Al Jamawat</option>
<option value="al jamiah">Al Jamiah</option>
<option value="al jassah">Al Jassah</option>
<option value="al jumah">Al Jumah</option>
<option value="al khalidiyah">Al Khalidiyah</option>
<option value="al khatim">Al Khatim</option>
<option value="al mabuth">Al Mabuth</option>
<option value="al manakhah">Al Manakhah</option>
<option value="al masani">Al Masani</option>
<option value="al mughaisilah">Al Mughaisilah</option>
<option value="al qalah">Al Qalah</option>
<option value="al qaswa">Al Qaswa</option>
<option value="al qiblatayn">Al Qiblatayn</option>
<option value="al usayfirin">Al Usayfirin</option>
<option value="al usbah">Al Usbah</option>
<option value="al uyun">Al Uyun</option>
<option value="al wabra">Al Wabra</option>
<option value="an nakhil">An Nakhil</option>
<option value="an naqa">An Naqa</option>
<option value="an naqs">An Naqs</option>
<option value="ar ranuna">Ar Ranuna</option>
<option value="ar rawabi">Ar Rawabi</option>
<option value="ar rayah">Ar Rayah</option>
<option value="ar rumanah">Ar Rumanah</option>
<option value="as sad">As Sad</option>
<option value="as sadiqiyah">As Sadiqiyah</option>
<option value="as sakb">As Sakb</option>
<option value="as salam">As Salam</option>
<option value="as sih">As Sih</option>
<option value="as sikkah al hadid">As Sikkah Al Hadid</option>
<option value="as suqya">As Suqya</option>
<option value="ash shafiyah">Ash Shafiyah</option>
<option value="ash shahba">Ash Shahba</option>
<option value="ash shuraybat">Ash Shuraybat</option>
<option value="ayn al khif">Ayn Al Khif</option>
<option value="az zahirah">Az Zahirah</option>
<option value="az zahrah">Az Zahrah</option>
<option value="badaah">Badaah</option>
<option value="bani abdul ashhal">Bani Abdul Ashhal</option>
<option value="bani bayadah">Bani Bayadah</option>
<option value="bani harithah">Bani Harithah</option>
<option value="bani khidrah">Bani Khidrah</option>
<option value="bani muawiyah">Bani Muawiyah</option>
<option value="bani zafar">Bani Zafar</option>
<option value="bir uthman">Bir Uthman</option>
<option value="dhu al hulayfah">Dhu Al Hulayfah</option>
<option value="hathm">Hathm</option>
<option value="industrial area">Industrial Area</option>
<option value="jabal uhud">Jabal Uhud</option>
<option value="king fahd">King Fahd</option>
<option value="kittanah">Kittanah</option>
<option value="mahzur">Mahzur</option>
<option value="masjid ad dar">Masjid Ad Dar</option>
<option value="mudhainib">Mudhainib</option>
<option value="nubala">Nubala</option>
<option value="qalat makhit">Qalat Makhit</option>
<option value="qurban">Qurban</option>
<option value="raht">Raht</option>
<option value="sayed ash shuhada">Sayed Ash Shuhada</option>
<option value="shizat">Shizat</option>
<option value="Warqan">Warqan</option>
<option value="Al Anahi">Al Anahi</option>
<option value="Mahayel Aseer">Mahayel Aseer</option>
<option value="Wadi Al-Dawaser">Wadi Al-Dawaser</option>
<option value="Dhahran Al Janub">Dhahran Al Janub</option>
<option value="Bani Kabeer">Bani Kabeer</option>
<option value="Abu `Arish">Abu `Arish</option>
<option value="Samtah">Samtah</option>
<option value="Sabya">Sabya</option>
<option value="Fefa">Fefa</option>
<option value="Ahad al Masarihah">Ahad al Masarihah</option>
<option value="Wadi Jizan">Wadi Jizan</option>
<option value="Aseer">Aseer</option>
<option value="Tareeb">Tareeb</option>
<option value="Belsamar">Belsamar</option>
<option value="Fursan">Fursan</option>
<option value="Al-Tawal">Al-Tawal</option>
<option value="Muwassam">Muwassam</option>
<option value="Damad">Damad</option>
<option value="Al-Aridhah">Al-Aridhah</option>
<option value="Ad Dareb">Ad Dareb</option>
<option value="Al-Khubah">Al-Khubah</option>
<option value="Badr Al Janoub">Badr Al Janoub</option>
<option value="Al-Edabi">Al-Edabi</option>
<option value="Al-Shuqaiq">Al-Shuqaiq</option>
<option value="Sabt Al-Alaya">Sabt Al-Alaya</option>
<option value="Al-Bahah">Al-Bahah</option>
<option value="al shorouk">Al Shorouk</option>
<option value="ad dabab">Ad Dabab</option>
<option value="al azhar">Al Azhar</option>
<option value="al baher">Al Baher</option>
<option value="al buraydah">Al Buraydah</option>
<option value="al hijaz">Al Hijaz</option>
<option value="al muratafaah">Al Muratafaah</option>
<option value="al naseem">Al Naseem</option>
<option value="al nuzhah">Al Nuzhah</option>
<option value="al rabwah">Al Rabwah</option>
<option value="al rawdha">Al Rawdha</option>
<option value="al safa">Al Safa</option>
<option value="as salamah">As Salamah</option>
<option value="ash shifa">Ash Shifa</option>
<option value="at tafeer">At Tafeer</option>
<option value="shahbah">Shahbah</option>
<option value="Rejal Almaa">Rejal Almaa</option>
<option value="Tanuma">Tanuma</option>
<option value="Sarat Ubaida">Sarat Ubaida</option>
<option value="Tathlith">Tathlith</option>
<option value="Al Namas">Al Namas</option>
<option value="Al Roboah">Al Roboah</option>
<option value="Khamis Moshait">Khamis Moshait</option>
<option value="14th brigade housing">14th Brigade Housing</option>
<option value="ad dawhah">Ad Dawhah</option>
<option value="airforce housing">Airforce Housing</option>
<option value="al aziziyah">Al Aziziyah</option>
<option value="al bawadi">Al Bawadi</option>
<option value="al fath">Al Fath</option>
<option value="al harir">Al Harir</option>
<option value="al humaylah">Al Humaylah</option>
<option value="al irq al janubi">Al Irq Al Janubi</option>
<option value="al irq ash shamali">Al Irq Ash Shamali</option>
<option value="al jazirah">Al Jazirah</option>
<option value="al khalidiyah">Al Khalidiyah</option>
<option value="al khazan">Al Khazan</option>
<option value="al mamurah">Al Mamurah</option>
<option value="al mathnah">Al Mathnah</option>
<option value="al mizab">Al Mizab</option>
<option value="al muntazah">Al Muntazah</option>
<option value="al musa subdivision">Al Musa Subdivision</option>
<option value="al qafilah">Al Qafilah</option>
<option value="al wahah">Al Wahah</option>
<option value="an nahdah">An Nahdah</option>
<option value="an nakhil">An Nakhil</option>
<option value="an nasim">An Nasim</option>
<option value="an nuzhah">An Nuzhah</option>
<option value="ar rabi">Ar Rabi</option>
<option value="ar rasras">Ar Rasras</option>
<option value="ar rawdah">Ar Rawdah</option>
<option value="ar runah">Ar Runah</option>
<option value="as sad">As Sad</option>
<option value="as salam">As Salam</option>
<option value="as suqur">As Suqur</option>
<option value="ash sharafiyah">Ash Sharafiyah</option>
<option value="ash shifa">Ash Shifa</option>
<option value="atud">Atud</option>
<option value="az zuhur">Az Zuhur</option>
<option value="damak">Damak</option>
<option value="dhalalah">Dhalalah</option>
<option value="military city housing">Military City Housing</option>
<option value="qambar">Qambar</option>
<option value="shakar">Shakar</option>
<option value="shubaah">Shubaah</option>
<option value="tayib al ism">Tayib Al Ism</option>
<option value="umm surur">Umm Surur</option>
<option value="Abha">Abha</option>
<option value="Ahad Rafida">Ahad Rafida</option>
<option value="Jazan">Jazan</option>
<option value="Najran">Najran</option>
<option value="as sahban subdivision">As Sahban Subdivision</option>
<option value="aba lasaud">Aba Lasaud</option>
<option value="ad dubat">Ad Dubat</option>
<option value="al amir mishal">Al Amir Mishal</option>
<option value="al amlah">Al Amlah</option>
<option value="al araysah al janubi subdivision">Al Araysah Al Janubi Subdivision</option>
<option value="al araysah ash shamali subdivision">Al Araysah Ash Shamali Subdivision</option>
<option value="al athaybah ash shamaliyah">Al Athaybah Ash Shamaliyah</option>
<option value="al fahd">Al Fahd</option>
<option value="al faisaliyah">Al Faisaliyah</option>
<option value="al ghuwayla agricutural subdivision">Al Ghuwayla Agricultural Subdivision</option>
<option value="al hadn">Al Hadd</option>
<option value="al husayn agricultural subdivision">Al Husayn Agricultural Subdivision</option>
<option value="al jurbah">Al Jurbah</option>
<option value="al khalidiyah">Al Khalidiyah</option>
<option value="al mukhayam">Al Mukhayam</option>
<option value="al qabil">Al Qabil</option>
<option value="dahdah">Dahdah</option>
<option value="ibn jarullah residential subdivision">Ibn Jarullah Residential Subdivision</option>
<option value="nahufah agricultural subdivision">Nahufah Agricultural Subdivision</option>
<option value="nahufah residential subdivision">Nahufah Residential Subdivision</option>
<option value="najran regional airport">Najran Regional Airport</option>
<option value="rajla al janubiyah">Rajla Al Janubiyah</option>
<option value="rajla ash shamaliyah">Rajla Ash Shamaliyah</option>
<option value="shaib rir residential subdivision">Shaib Rir Residential Subdivision</option>
<option value="shamal ad dubat">Shamal Ad Dubat</option>
<option value="shamal al fahd">Shamal Al Fahd</option>
<option value="sharq ad dubat">Sharq Ad Dubat</option>
<option value="Almajaridah">Almajaridah</option>
<option value="Al Shakhikh">Al Shakhikh</option>
<option value="Bishah">Bishah</option>
<option value="Qelwah">Qelwah</option>
<option value="Habona">Habona</option>
<option value="Al-Ghat">Al-Ghat</option>
<option value="Darma">Darma</option>
<option value="Thadek">Thadek</option>
<option value="Marat">Marat</option>
<option value="Rawdet Sedair">Rawdet Sedair</option>
<option value="Al-Dariya">Al-Dariya</option>
<option value="Al-Zulfi">Al-Zulfi</option>
<option value="Al-Dawadmi">Al-Dawadmi</option>
<option value="Al-Aflaj">Al-Aflaj</option>
<option value="Hotet Bani Tameem">Hotet Bani Tameem</option>
<option value="Jalajail">Jalajail</option>
<option value="Al-Delem">Al-Delem</option>
<option value="Al-Hareeq">Al-Hareeq</option>
<option value="As Sulayyil">As Sulayyil</option>
<option value="Al-Muzahmiyya">Al-Muzahmiyya</option>
<option value="Tameer">Tameer</option>
<option value="Afif">Afif</option>
<option value="Sajer">Sajer</option>
<option value="Rimah">Rimah</option>
<option value="Al-Bijadyah">Al-Bijadyah</option>
<option value="Al-Helwa">Al-Helwa</option>
<option value="Ainya wa Jobailya">Ainya wa Jobailya</option>
<option value="Al-Quway'iyah">Al-Quway'iyah</option>
<option value="Hotet Sdair">Hotet Sdair</option>
<option value="Al-Hayathem">Al-Hayathem</option>
<option value="Al-Reen">Al-Reen</option>
<option value="Al-Roaida">Al-Roaida</option>
<option value="Nafy">Nafy</option>
<option value="Al-Gasab">Al-Gasab</option>
<option value="Al-Hadar">Al-Hadar</option>
<option value="Al-Artawya">Al-Artawya</option>
<option value="Al-Gamash">Al-Gamash</option>
<option value="Jabba">Jabba</option>
<option value="Bakaa">Bakaa</option>
<option value="Al-Rawdah">Al-Rawdah</option>
<option value="Mokek">Mokek</option>
<option value="Heet">Heet</option>
<option value="Al-Kahfa">Al-Kahfa</option>
<option value="Al-Shamli">Al-Shamli</option>
<option value="Al-Bekeria">Al-Bekeria</option>
<option value="Al-Badaea">Al-Badaea</option>
<option value="Riyadh Al-Khobara">Riyadh Al-Khobara</option>
<option value="Uyun AlJiwa">Uyun AlJiwa</option>
<option value="Al-Rafayea">Al-Rafayea</option>
<option value="Jufayer">Jufayer</option>
<option value="Yabreen">Yabreen</option>
<option value="al amal">al amal</option>
<option value="ad dar al baida">ad dar al baida</option>
<option value="ad difa">ad difa</option>
<option value="ad dirah">ad dirah</option>
<option value="ad dubiyah">Ad Dubiyah</option>
<option value="ad duraihimiyah">Ad Duraihimiyah</option>
<option value="adh dhubbat">Adh Dhubbat</option>
<option value="al andalus">Al Andalus</option>
<option value="al aqiq">Al Aqiq</option>
<option value="al arid">Al Arid</option>
<option value="al aziziyah">Al Aziziyah</option>
<option value="al badiah">Al Badiyah</option>
<option value="al bariah">Al Bariyah</option>
<option value="al batha">Al Batha</option>
<option value="al dho">Al Dho</option>
<option value="al faiha">Al Faiha</option>
<option value="al faisaliyah">Al Faisaliyah</option>
<option value="al fakhriyah">Al Fakhriyah</option>
<option value="al falah">Al Falah</option>
<option value="al faruq">Al Faruq</option>
<option value="al futah">Al Futah</option>
<option value="al ghadir">Al Ghadir</option>
<option value="al ghnamiah">Al Ghanamiah</option>
<option value="al hada">Al Hada</option>
<option value="al haer">Al Haer</option>
<option value="al hamra">Al Hamra</option>
<option value="al hazm">Al Hazm</option>
<option value="al imam muhammed ibnsaud">Al Imam Muhammed Ibn Saud</option>
<option value="al iskan">Al Iskan</option>
<option value="al izdihar">Al Izdihar</option>
<option value="al janadriyah">Al Janadriyah</option>
<option value="al jaradiyah">Al Jaradiyah</option>
<option value="al jazirah">Al Jazirah</option>
<option value="al khalidiyah">Al Khalidiyah</option>
<option value="al khalij">Al Khalij</option>
<option value="al khuzama">Al Khuzama</option>
<option value="al madhar">Al Madhar</option>
<option value="al madhar ash shamali">Al Madhar Ash Shamali</option>
<option value="al mahdiyah">Al Mahdiyah</option>
<option value="al maizilah">Al Maizilah</option>
<option value="al malaz">Al Malaz</option>
<option value="al malqa">Al Malqa</option>
<option value="al manakh">Al Manakh</option>
<option value="al manar">Al Manar</option>
<option value="al mansurah">Al Mansurah</option>
<option value="al marqab">Al Marqab</option>
<option value="al marwah">Al Marwah</option>
<option value="al masani">Al Masani</option>
<option value="al masif">Al Masif</option>
<option value="al mikal">Al Mikal</option>
<option value="al misfat">Al Misfat</option>
<option value="al mishal">Al Mishal</option>
<option value="al mughrizat">Al Mughrizat</option>
<option value="al muhammadiyah">Al Muhammadiyah</option>
<option value="al munsiyah">Al Munsiyah</option>
<option value="al murabba">Al Murabba</option>
<option value="al mursalat">Al Mursalat</option>
<option value="al muruj">Al Muruj</option>
<option value="al mutamarat">Al Mutamarat</option>
<option value="al qadisiyah">Al Qadisiyah</option>
<option value="al qirawan">Al Qirawan</option>
<option value="al quds">Al Quds</option>
<option value="al qura">Al Qura</option>
<option value="al shohada">Al Shohada</option>
<option value="al ud">Al Ud</option>
<option value="al ulaya">Al Ulaya</option>
<option value="al umjiah">Al Umjiah</option>
<option value="al uraija">Al Uraija</option>
<option value="al uraija al gharbiyah">Al Uraija Al Gharbiyah</option>
<option value="al uraija al wusta">Al Uraija Al Wusta</option>
<option value="al wadi">Al Wadi</option>
<option value="al wahah">Al Wahah</option>
<option value="al wazarat">Al Wazarat</option>
<option value="al wisham">Al Wisham</option>
<option value="al wurud">Al Wurud</option>
<option value="al wusayta">Al Wusayta</option>
<option value="al yamamah">Al Yamamah</option>
<option value="al yarmuk">Al Yarmuk</option>
<option value="al yasmin">Al Yasmin</option>
<option value="an nada">An Nada</option>
<option value="an nafl">An Nafl</option>
<option value="an nahdah">An Nahdah</option>
<option value="an nakhil">An Nakhil</option>
<option value="an namudhajiyah">An Namudhajiyah</option>
<option value="an narjis">An Narjis</option>
<option value="an nasim al gharbi">An Nasim Al Gharbi</option>
<option value="an nasim ash sharqi">An Nasim Ash Sharqi</option>
<option value="an nasriyah">An Nasriyah</option>
<option value="an nazim">An Nazim</option>
<option value="an nur">An Nur</option>
<option value="an nuzhah">An Nuzhah</option>
<option value="ar rabi">Ar Rabi</option>
<option value="ar rabwah">Ar Rabwah</option>
<option value="ar rafiah">Ar Rafiah</option>
<option value="ar rahmaniyah">Ar Rahmaniyah</option>
<option value="ar raid">Ar Raid</option>
<option value="ar rawabi">Ar Rawabi</option>
<option value="ar rawdah">Ar Rawdah</option>
<option value="ar rayan">Ar Ryan</option>
<option value="ar rimal">Ar Rimal</option>
<option value="ar rimayah">Ar Rimayah</option>
<option value="areed">Areed</option>
<option value="as saadah">As Saadah</option>
<option value="as safa">As Safa</option>
<option value="as safarat">As Safarat</option>
<option value="as sahafah">As Sahafah</option>
<option value="as salam">As Salam</option>
<option value="as salhiyah">As Salhiyah</option>
<option value="as sinaiyah">As Sinaiyah</option>
<option value="as sulai">As Sulai</option>
<option value="as sulimaniyah">As Sulimaniyah</option>
<option value="as suwaidi">As Suwaidi</option>
<option value="as suwaidi al gharbi">As Suwaidi Al Gharbi</option>
<option value="ash sharafiyah">Ash Sharafiyah</option>
<option value="ash sharq">Ash Sharq</option>
<option value="ash shifa">Ash Shifa</option>
<option value="ash shimaisi">Ash Shimaisi</option>
<option value="at taawun">At Taawun</option>
<option value="az zahra">Az Zahra</option>
<option value="az zahrah">Az Zahrah</option>
<option value="badr">Badr</option>
<option value="banban">Banban</option>
<option value="dahiat namar">Dahiat Namar</option>
<option value="dhahrat al badiah">Dhahrat Al Badiah</option>
<option value="dhahrat laban">Dhahrat Laban</option>
<option value="dhahrat namar">Dhahrat Namar</option>
<option value="dirab">Dirab</option>
<option value="ghirnatah">Ghirnatah</option>
<option value="ghubairah">Ghubairah</option>
<option value="hittin">Hittin</option>
<option value="hyt">Hyt</option>
<option value="ilaishah">Ilaishah</option>
<option value="irqah">Irqah</option>
<option value="ishbiliyah">Ishbiliyah</option>
<option value="jabrah">Jabrah</option>
<option value="jarir">Jarir</option>
<option value="khashm al an">Khashm Al An</option>
<option value="king abdul aziz">King Abdul Aziz</option>
<option value="king abdullah">King Abdullah</option>
<option value="king fahd">King Fahd</option>
<option value="king faisal">King Faisal</option>
<option value="king khalid airport">King Khalid Airport</option>
<option value="king saud university">King Saud University</option>
<option value="laban">Laban</option>
<option value="manfuhah">Manfuhah</option>
<option value="manfuhah al jadidah">Manfuhah Al Jadidah</option>
<option value="mansuriyah">Mansuriyah</option>
<option value="namar">Namar</option>
<option value="qurtubah">Qurtubah</option>
<option value="salah ad din">Salah Ad Din</option>
<option value="second industrial city">Second Industrial City</option>
<option value="shubra">Shubra</option>
<option value="siyah">Siyah</option>
<option value="skirinah">Skirinah</option>
<option value="sultanah">Sultanah</option>
<option value="taibah">Taibah</option>
<option value="thulaim">Thulaim</option>
<option value="tuwaiq">Tuwaiq</option>
<option value="uhud">Uhud</option>
<option value="um alshal">Um Alshal</option>
<option value="umm al hamam al gharbi">Umm Al Hamam Al Gharbi</option>
<option value="umm al hamam ash sharqi">Umm Al Hamam Ash Sharqi</option>
<option value="umm salim">Umm Salim</option>
<option value="uqaz">Uqaz</option>
<option value="utaiqah">Utaiqah</option>
<option value="Al Fawaz">Al Fawaz</option>
<option value="Shaqra">Shaqra</option>
<option value="Rawdat sdier">Rawdat Sdier</option>
<option value="Eqlat-Al Soqor">Eqlat-Al Soqor</option>
<option value="Al-Kharj">Al-Kharj</option>
<option value="Al-Jouf">Al-Jouf</option>
<option value="Buraidah">Buraidah</option>
<option value="at tashlih">At Tashlih</option>
<option value="Al-Jouf">Al-Jouf</option>
<option value="Buraidah">Buraidah</option>
<option value="at tashlih">At Tashlih</option>
<option value="ad dahi">Ad Dahi</option>
<option value="ad duaysah">Ad Duaysah</option>
<option value="al akhdar">Al Akhdar</option>
<option value="al amn">Al Amn</option>
<option value="al basr">Al Basr</option>
<option value="al bishr">Al Bishr</option>
<option value="al buraykah">Al Buraykah</option>
<option value="al fakhriyah">Al Fakhriyah</option>
<option value="al fayziyah">Al Fayziyah</option>
<option value="al ghamas">Al Ghamas</option>
<option value="al hamr">Al Hamr</option>
<option value="al haql al akhdar">Al Haql Al Akhdar</option>
<option value="al hasat">Al Hasat</option>
<option value="al hilal">Al Hilal</option>
<option value="al huwayqiyah">Al Huwayqiyah</option>
<option value="al iskan">Al Iskan</option>
<option value="al jamiyin">Al Jamiyin</option>
<option value="al jardah">Al Jardah</option>
<option value="al khadar and al wujayan">Al Khadar and Al Wujayan</option>
<option value="al khalidiyah">Al Khalidiyah</option>
<option value="al khalij">Al Khalij</option>
<option value="al khubaybiyah">Al Khubaybiyah</option>
<option value="al lasib">Al Lasib</option>
<option value="al malik">Al Malik</option>
<option value="al mawta">Al Mawta</option>
<option value="al mizallal">Al Mizallal</option>
<option value="al muntazah">Al Muntazah</option>
<option value="al muntazah al janubi">Al Muntazah Al Janubi</option>
<option value="Unayzah">Unayzah</option>
<option value="hteen">Hteen</option>
<option value="ad dahbi">Ad Dahbi</option>
<option value="airport">Airport</option>
<option value="al absiah">Al Absiah</option>
<option value="al aqiq">Al Aqiq</option>
<option value="al ashrafiyah">Al Ashrafiyah</option>
<option value="al awniyah">Al Awniyah</option>
<option value="al badiah">Al Badiah</option>
<option value="al buaiten">Al Buaiten</option>
<option value="al bustan">Al Bustan</option>
<option value="al faiha">Al Faiha</option>
<option value="al faisaliyah">Al Faisaliyah</option>
<option value="al fakamah">Al Fakamah</option>
<option value="al fakhriyah">Al Fakhriyah</option>
<option value="al hadaa">Al Hadaa</option>
<option value="al hadiqah">Al Hadiqah</option>
<option value="al hajeb">Al Hajeb</option>
<option value="al hamra">Al Hamra</option>
<option value="al hazm">Al Hazm</option>
<option value="al hufayrah">Al Hufayrah</option>
<option value="al iskan">Al Iskan</option>
<option value="al jaldah">Al Jaldah</option>
<option value="al jamai">Al Jamai</option>
<option value="al jawharah">Al Jawharah</option>
<option value="al jazirah">Al Jazirah</option>
<option value="al kada">Al Kada</option>
<option value="al khalidiyah">Al Khalidiyah</option>
<option value="al khalij">Al Khalij</option>
<option value="Al-Rass">Al-Rass</option>
<option value="an nawwariyah">An Nawwariyah</option>
<option value="khabra">Khabra</option>
<option value="al nabhaniah">Al Nabhaniah</option>
<option value="Al-Qurayat">Al-Qurayat</option>
<option value="AL-jemsh">AL-Jemsh</option>
<option value="AL-Asyiah">AL-Asyiah</option>
<option value="AL-Shamasiah">AL-Shamasiah</option>
<option value="Al-Mozanab">Al-Mozanab</option>
                      </select>
                    </div>
               
                  <div className="form-row">
                    <div className="col" id="message">
                      <label>Interested In</label>
                      <textarea
                        className="form-control"
                        type="text"
                        name="interestedIn"
                        placeholder="Interested In"
                        value={formValues.interestedIn}
                        onChange={this.handleUserInterestedInChange}
                      />
                      {formErrors.interestedIn && <p style={{ color: 'red' }}>{formErrors.interestedIn}</p>}

                    </div>
                  </div>
                  <div className="form-button">
                    <button className="ibtn extra-padding" id="btncolor" type="submit">Send</button>
                  </div>

                </form>
                </div>
  )}
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default EngForm;