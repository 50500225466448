import React, { Component } from 'react';
import axios from 'axios';
import FanarLogo from '../imgs/alfanar-logo.svg';
import imagebanar from '../imgs/main-banner.jpg';
import '../css/ArabicStyle.css'



class ArForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formValues: this.initialValues(),
      formErrors: {},
      isSubmit: false,
      isSuccess: false,
      FormHidden: true
    };
  }

  initialValues = () => {
    return {
      username: '',
      phone: '',
      city: '',
      area: '',
      interestedIn: '',
    };
  };

  validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = 'يجب عليك كتابة اسم المستخدم ';
    } else if (values.username.length < 3) {
      errors.username = 'يجب أن يتكون اسم المستخدم من 3 أحرف على الأقل';
    }
    if (!values.phone) {
      errors.phone = 'يجب عليك ادخال رقم الهاتف';
    } else if (!/^\d{10}$/.test(values.phone)) {
      errors.phone = 'يجب أن يكون رقم الهاتف مكونًا من 10 أرقام';
    }
    if (!values.city) {
      errors.city = 'الرجاء اختيار المدينة';
    }
    if (values.interestedIn.length > 120) {
      errors.interestedIn = 'يجب أن لا تكون الرسالة أكثر من 120 حرف';
    }
    return errors;
  };

  handleValidation() {
    const { formValues } = this.state;
    const errors = this.validate(formValues);
    this.setState({ formErrors: errors });
    return Object.keys(errors).length === 0;
  }

  handleUserNameChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, username: event.target.value },
    });
  };

  handleUserPhoneChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, phone: event.target.value },
    });
  };

  handleUserCityChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, city: event.target.value },
    });
  };
  handleUserAreaChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, area: event.target.value },
    });
  };

  handleUserInterestedInChange = (event) => {
    this.setState({
      formValues: { ...this.state.formValues, interestedIn: event.target.value },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { formValues } = this.state;

    if (this.handleValidation()) {
      axios
        .post('https://prod-225.westeurope.logic.azure.com/workflows/249cda1af2b947e99b702e0ada6b8821/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=KljFd3IrHEFNty9VO2YKv0r6_o_vn7DXdRK1oKK_tgM', {
          Name: formValues.username,
          Mobile: parseInt(formValues.phone),
          City: formValues.city,
          Area: formValues.area,
          InterestedIn: formValues.interestedIn,
        })
        .then((res) => {
          console.log(res);
          console.log(res.data);
          this.setState({ isSuccess: true, FormHidden:false });
          this.resetForm();
        })
        .catch((error) => {
          console.error('Error making API call:', error);
          console.log(error.response.data); // Log the response data
          console.log(error.response.status); // Log the response status code
        });
    } else {
      console.log('Form has errors.');
    }
  };

  resetForm = () => {
    this.setState({ formValues: this.initialValues(), isSubmit: false });
  };



  render() {
    const { formValues, formErrors, isSuccess, isSubmit ,FormHidden} = this.state;
    return (
      <div className="container no-padding">
        <a className="ar_langar" href="/">
          <i className="fa fa-globe" />
          <p style={{ color: 'white' }}>EN</p>
        </a>

        <div className="logo">
          <img className="logo-size" src={FanarLogo} alt='Alfanar' />
        </div>
        <img src={imagebanar} alt='Alfanar' style={{ width: '100%' }} />
        <div className="col-sm-12 form-body without-side ">
          <div className="Ar-lang">
            <div className="padder" style={{ borderBottom: '8px solid #f3f3f3', textAlign: 'center' }}>
              <h4>اكتشف مفاتيح وأفياش الفنار</h4>
              <p>تمتاز بتصاميم رائعة بألوان وأشكال متنوعة</p>
      <p>جودة عالية وضمان حقيقي يجعلانها اختيارًا موثوقًا</p>
      <p>حلول متكاملة من المفاتيح والأفياش</p>
      <p>خدمة صيانة مجانية لتضمن لك الاستمرارية</p>
      <p>صناعة سعودية</p>
              <a href="https://www.alfanar.com/arabic/Electrical-switches-sockets" target="_blank" rel="noopener noreferrer">
                <button className="btn btn-info">المزيد...</button>
              </a>
            </div>
            <div className="form-holder">
              <div className="form-content">
                <div className="form-items">
                  {isSuccess && (
                    <div>
                      <h2 style={{ color: 'green', textAlign: 'center' }}>شكرا لاهتمامك.</h2>
                      <p>سيتم التواصل من قبل مندوب الفنار في أسرع وقت ممكن</p>
                    </div>
                  )}
                                    {FormHidden && (

                      <div> 
                  <h3>شكرا لاهتمامك.</h3>
                  <p>للطلب او اي استفسار الرجاء تعبئة النموذج في الاسفل ليتم التوصل من قبل مندوب الفنار</p>
                  <form onSubmit={this.handleSubmit}>
                    <label>الإسم</label>
                    <input
                      type="text"
                      name="username"
                      placeholder="الإسم"
                      value={formValues.username}
                      onChange={this.handleUserNameChange} />
                    {formErrors.username && <p style={{ color: 'red' }}>{formErrors.username}</p>}
                    <label>الهاتف</label>
                    <input
                      type="text"
                      name="phone"
                      placeholder="رقم الهاتف"
                      value={formValues.phone}
                      onChange={this.handleUserPhoneChange} />
                    {formErrors.phone && <p style={{ color: 'red' }}>{formErrors.phone}</p>}

                    <label>المدينة</label>
                    <select
                      name="city"
                      placeholder="City"
                      value={formValues.city}
                      onChange={this.handleUserCityChange}
                    >
                      <option value>إختر المدينة</option>
                      <option value="الرياض">الرياض</option>
                      <option value="جدة">جدة</option>
                      <option value="مكة المكرمة">مكة المكرمة</option>
                      <option value="المدينه المنوره">المدينة المنورة</option>
                      <option value="الدمام">الدمام</option>
                      <option value="الهفوف">الهفوف</option>
                      <option value="حفر الباطن">حفر الباطن</option>
                      <option value="الحلة">الحلة</option>
                      <option value="الطائف">الطائف</option>
                      <option value="تبوك">تبوك</option>
                      <option value="القطيف">القطيف</option>
                      <option value="بريده">بريدة</option>
                      <option value="الجبيل">الجبيل</option>
                      <option value="حائل">حائل</option>
                      <option value="الخرج">الخرج</option>
                      <option value="القنفذة">القنفذة</option>
                      <option value="المبرز">المبرز</option>
                      <option value="ينبع">ينبع</option>
                      <option value="سكاكا">سكاكا</option>
                      <option value="أبها">أبها</option>
                      <option value="صبيا">صبيا</option>
                      <option value="الخبر">الخبر</option>
                      <option value="قلعة بيشة">قلعة بيشة</option>
                      <option value="عنيزة">عنيزة</option>
                      <option value="رأس تنورة">رأس تنورة</option>
                      <option value="الحوية">الحوية</option>
                      <option value="القريات">القريات</option>
                      <option value="الرس">الرس</option>
                      <option value="جازان">جازان</option>
                      <option value="الزلفي">الزلفي</option>
                      <option value="سيهات">سيهات</option>
                      <option value="حرض">حرض</option>
                      <option value="الأحد المسارحة">الأحد المسارحة</option>
                      <option value="خميس مشيط">خميس مشيط</option>
                      <option value="طريف">طريف</option>
                      <option value="شرورة">شرورة</option>
                      <option value="رفحاء">رفحاء</option>
                      <option value="نجران">نجران</option>
                      <option value="الليث">الليث</option>
                      <option value="الدرب">الدرب</option>
                      <option value="رأس الخفجي">رأس الخفجي</option>
                      <option value="بدر حنين">بدر حنين</option>
                      <option value="خليص">خليص</option>
                      <option value="آن نيماس">آن نيماس</option>
                      <option value="المجردة">المجردة</option>
                      <option value="الوجه">الوجه</option>
                      <option value="المدناب">المدناب</option>
                      <option value="بقيق">بقيق</option>
                      <option value="العقيق">العقيق</option>
                      <option value="حلي رشيد">حلي رشيد</option>
                      <option value="شقراء">شقراء</option>
                      <option value="المندق">المندق</option>
                      <option value="الظهران">الظهران</option>
                      <option value="العيدابي">العيدابي</option>
                      <option value="قره">قره</option>
                      <option value="الشنان">الشنان</option>
                      <option value="عرعر">عرعر</option>
                      <option value="حقل">حقل</option>
                      <option value="ضباء">ضباء</option>
                      <option value="الباحة">الباحة</option>
                    </select>
                    {formErrors.city && <p style={{ color: 'red' }}>{formErrors.city}</p>}
                    <div>
                      <label>المنطقة</label>
                      <select
                        name="area"
                        placeholder="Area"
                        value={formValues.area}
                        onChange={this.handleUserAreaChange}
                      >
                        <option value>اختر المنطقة </option>
                        <option value="طريف">طريف</option>
<option value="دومة الجندل">دومة الجندل</option>
<option value="طبرجل">طبرجل</option>
<option value="القيصومة">القيصومة</option>
<option value="العيسوية">العيسوية</option>
<option value="العويقيلة">العويقيلة</option>
<option value="الوجه">الوجه</option>
<option value="أملج">أملج</option>
<option value="تيماء">تيماء</option>
<option value="حقل">حقل</option>
<option value="بير بن هرماس">بير بن هرماس</option>
<option value="رفحاء">رفحاء</option>
<option value="صوير">صوير</option>
<option value="الحدود الشمالية">الحدود الشمالية</option>
<option value="سكاكا">سكاكا</option>
<option value="القريات">القريات</option>
<option value="عرعر">عرعر</option>
<option value="الهفوف">الهفوف</option>
<option value="بقيق">بقيق</option>
<option value="يهوذا">يهوذا</option>
<option value="رأس تنورة">رأس تنورة</option>
<option value="الخفجي">الخفجي</option>
<option value="العيوان">العيوان</option>
<option value="العليا">العليا</option>
<option value="المليجة">المليجة</option>
<option value="اسرار">اسرار</option>
<option value="المبرز">المبرز</option>
<option value="اجا">اجا</option>
<option value="المصيف">المصيف</option>
<option value="المنتزه الغربي">المنتزه الغربي</option>
<option value="المنتزه الشرقي">المنتزه الشرقي</option>
<option value="المزعبار">المزعبار</option>
<option value="الوسيطة">الوسيطة</option>
<option value="النقرة">النقرة</option>
<option value="النساح">النساح</option>
<option value="السمرا">السمرا</option>
<option value="السويفلة">السويفلة</option>
<option value="الزهراء">الزهراء</option>
<option value="مطار">مطار</option>
<option value="الزبارة">الزبارة</option>
<option value="برزان">برزان</option>
<option value="حضري البلاد">حضري البلاد</option>
<option value="مطار حائل الإقليمي">مطار حائل الإقليمي</option>
<option value="المنطقة الصناعية">المنطقة الصناعية</option>
<option value="اللبدة">اللبدة</option>
<option value="المحطة">المحطة</option>
<option value="مغيضة">مغيضة</option>
<option value="صباح">صباح</option>
<option value="سماح">سماح</option>
<option value="العزيزية">العزيزية</option>
<option value="البدنة">البدنة</option>
<option value="البحيرة">البحيرة</option>
<option value="الخماشية">الخماشية</option>
<option value="الخريمي">الخريمي</option>
<option value="الخزامى">الخزامى</option>
<option value="اللقيحة">اللقيحة</option>
<option value="قيفار">قيفار</option>
<option value="المدائن">المدائن</option>
<option value="الدمام">الدمام</option>
<option value="الخالدية الشمالية">الخالدية الشمالية</option>
<option value="الضباب">الضباب</option>
<option value="الدانة">الدانة</option>
<option value="الدواسر">الدواسر</option>
<option value="الأدامح">الأدامح</option>
<option value="الأمل">الأمل</option>
<option value="العماررة">العماررة</option>
<option value="الأمانة">الأمانة</option>
<option value="الأمير محمد بن سعود">الأمير محمد بن سعود</option>
<option value="العنود">العنود</option>
<option value="الأنوار">الأنوار</option>
<option value="الأثير">الأثير</option>
<option value="العزيزية">العزيزية</option>
<option value="البادي">البادي</option>
<option value="البادية">البادية</option>
<option value="البساتين">البساتين</option>
<option value="البحيرة">البحيرة</option>
<option value="الفيحاء">الفيحاء</option>
<option value="الفيصلية">الفيصلية</option>
<option value="الفنار">الفنار</option>
<option value="الفردوس">الفردوس</option>
<option value="الفرسان">الفرسان</option>
<option value="الهضبة">الهضبة</option>
<option value="الحمرا">الحمرا</option>
<option value="الحسام">الحسام</option>
<option value="الاسكان">الاسكان</option>
<option value="الاتصالات">الاتصالات</option>
<option value="الجلوية">الجلوية</option>
<option value="الجامعين">الجامعين</option>
<option value="الجوهرة">الجوهرة</option>
<option value="الخالدية الجنوبية">الخالدية الجنوبية</option>
<option value="الخليج">الخليج</option>
<option value="الكوثرية">الكوثرية</option>
<option value="المها">المها</option>
<option value="المنار">المنار</option>
<option value="المطار">المطار</option>
<option value="المزروعية">المزروعية</option>
<option value="المحمدية">المحمدية</option>
<option value="المنتزه">المنتزه</option>
<option value="المريكبات">المريكبات</option>
<option value="القادسية">القادسية</option>
<option value="القزاز">القزاز</option>
<option value="الواحة">الواحة</option>
<option value="الندى">الندى</option>
<option value="النهضة">النهضة</option>
<option value="النخيل">النخيل</option>
<option value="النسيم">النسيم</option>
<option value="الناصرية">الناصرية</option>
<option value="النورس">النورس</option>
<option value="النور">النور</option>
<option value="النزهة">النزهة</option>
<option value="الربيع">الربيع</option>
<option value="الرابية">الرابية</option>
<option value="الراكة الشمالية">الراكة الشمالية</option>
<option value="الروضة">الروضة</option>
<option value="الريان">الريان</option>
<option value="الصدفة">الصدفة</option>
<option value="الصفا">الصفا</option>
<option value="السيف">السيف</option>
<option value="السلام">السلام</option>
<option value="السوق">السوق</option>
<option value="الشرق">الشرق</option>
<option value="الشاطئ الغربي">الشاطئ الغربي</option>
<option value="الشاطئ الشرقي">الشاطئ الشرقي</option>
<option value="الشفا">الشفا</option>
<option value="الشولا">الشولا</option>
<option value="في الطبيشي">في الطبيشي</option>
<option value="الزهور">الزهور</option>
<option value="بدر">بدر</option>
<option value="ضاحية الملك فهد">ضاحية الملك فهد</option>
<option value="غرناطة">غرناطة</option>
<option value="ابن خلدون">ابن خلدون</option>
<option value="صناعي">صناعي</option>
<option value="المنطقة الصناعية رقم 1">المنطقة الصناعية رقم 1</option>
<option value="المدينة الصناعية 2">المدينة الصناعية 2</option>
<option value="ميناء الملك عبد العزيز">ميناء الملك عبد العزيز</option>
<option value="مطار الملك فهد الدولي">مطار الملك فهد الدولي</option>
<option value="جامعة الملك فيصل">جامعة الملك فيصل</option>
<option value="مدينة الأمل">مدينة الأمل</option>
<option value="قصر الخليج">قصر الخليج</option>
<option value="طيبة">طيبة</option>
<option value="أحد">أحد</option>
<option value="العروبة">العروبة</option>
<option value="الظهران">الظهران</option>
<option value="الخبر">الخبر</option>
<option value="القطيف">القطيف</option>
<option value="الجبيل">الجبيل</option>
<option value="الأحساء">الأحساء</option>
<option value="منطقة الزراعة">منطقة الزراعة</option>
<option value="الجشة">الجشة</option>
<option value="المنايزلة">المنايزلة</option>
<option value="لدى تارف">لدى تارف</option>
<option value="جواثا">جواثا</option>
<option value="مدينة الدول">مدينة الدول</option>
<option value="مدينة الجفر">مدينة الجفر</option>
<option value="مدينة العمران">مدينة العمران</option>
<option value="مدينة العيون">مدينة العيون</option>
<option value="حفر الباطن">حفر الباطن</option>
<option value="الربيع">الربيع</option>
<option value="أبو موسى الأشعري">أبو موسى الأشعري</option>
<option value="العزيزية">العزيزية</option>
<option value="البلدية">البلدية</option>
<option value="الباطن">الباطن</option>
<option value="الفيحاء">الفيحاء</option>
<option value="الفيصلية">الفيصلية</option>
<option value="الاسكان">الاسكان</option>
<option value="الجامعة">الجامعة</option>
<option value="الخالدية">الخالدية</option>
<option value="الخليج">الخليج</option>
<option value="المنار">المنار</option>
<option value="المصيف">المصيف</option>
<option value="المحمدية">المحمدية</option>
<option value="المروج">المروج</option>
<option value="الوادي">الوادي</option>
<option value="الواحة">الواحة</option>
<option value="الورود">الورود</option>
<option value="اليرموك">اليرموك</option>
<option value="النهضة">النهضة</option>
<option value="النخيل">النخيل</option>
<option value="النافية">النافية</option>
<option value="النزهة">النزهة</option>
<option value="الرابية">الرابية</option>
<option value="الربوة">الربوة</option>
<option value="غارة">غارة</option>
<option value="الروضة">الروضة</option>
<option value="الريان">الريان</option>
<option value="الصفاء">الصفاء</option>
<option value="الصناعية">الصناعية</option>
<option value="السليمانية">السليمانية</option>
<option value="الشفا">الشفا</option>
<option value="لدى طلال">لدى طلال</option>
<option value="الفليج">الفليج</option>
<option value="غرناطة">غرناطة</option>
<option value="قرطبة">قرطبة</option>
<option value="هفر باتين">هفر باتين</option>
<option value="العمران">العمران</option>
<option value="المثنب">المثنب</option>
<option value="الجموم">الجموم</option>
<option value="ينبع النخل">ينبع النخل</option>
<option value="العيس">العيس</option>
<option value="البيضاء">البيضاء</option>
<option value="عشيرة">عشيرة</option>
<option value="بن مالك">بن مالك</option>
<option value="بن سعد">بن سعد</option>
<option value="ميسان">ميسان</option>
<option value="بلقارين">بلقارين</option>
<option value="رنية">رنية</option>
<option value="طروبة">طروبة</option>
<option value="الخرمة">الخرمة</option>
<option value="رابغ">رابغ</option>
<option value="الليث">الليث</option>
<option value="خيبر">خيبر</option>
<option value="مهد الذهب">مهد الذهب</option>
<option value="وادي الفوروا">وادي الفوروا</option>
<option value="المجردة">المجردة</option>
<option value="القنفذة">القنفذة</option>
<option value="خليص">خليص</option>
<option value="القوز">القوز</option>
<option value="المويه">المويه</option>
<option value="الكامل">الكامل</option>
<option value="ثريبان">ثريبان</option>
<option value="بدر">بدر</option>
<option value="العلا">العلا</option>
<option value="الحناكية">الحناكية</option>
<option value="البشير">البشير</option>
<option value="بارق">بارق</option>
<option value="بلجرشي">بلجرشي</option>
<option value="المندق">المندق</option>
<option value="الكلوة">الكلوة</option>
<option value="المخوة">المخوة</option>
<option value="العقيق">العقيق</option>
<option value="دبا">دبا</option>
<option value="البدع">البدع</option>
<option value="الحوية">الحوية</option>
<option value="الشرفية">الشرفية</option>
<option value="عاطف">عاطف</option>
<option value="الطائف">الطائف</option>
<option value="أم العاد">أم العاد</option>
<option value="العقيق">العقيق</option>
<option value="العزيزية">العزيزية</option>
<option value="الفيصلية">الفيصلية</option>
<option value="الخالدية">الخالدية</option>
<option value="المثنى">المثنى</option>
<option value="القمرية">القمرية</option>
<option value="القطبية">القطبية</option>
<option value="الوشحة">الوشحة</option>
<option value="النزهة">النزهة</option>
<option value="الريان">الريان</option>
<option value="السداد">السداد</option>
<option value="السلامة">السلامة</option>
<option value="الشرقية">الشرقية</option>
<option value="الشهداء الجنوبية">الشهداء الجنوبية</option>
<option value="الشهداء الشمالية">الشهداء الشمالية</option>
<option value="عودة">عودة</option>
<option value="المنطقة المركزية">المنطقة المركزية</option>
<option value="هوايا">هوايا</option>
<option value="جبرة">جبرة</option>
<option value="معاشي">معاشي</option>
<option value="مسرة">مسرة</option>
<option value="نخب">نخب</option>
<option value="قروة">قروة</option>
<option value="شيهار">شيهار</option>
<option value="شبرا">شبرا</option>
<option value="المجمعة">المجمعة</option>
<option value="أز زمرد">أز زمرد</option>
<option value="أبحر الجنوبي">أبحر الجنوبي</option>
<option value="أبحر الشمالية">أبحر الشمالية</option>
<option value="ابروق الرغامة">ابروق الرغامة</option>
<option value="الضاحية">الضاحية</option>
<option value="العدل">العدل</option>
<option value="العجاويد">العجاويد</option>
<option value="الأجواد">الأجواد</option>
<option value="الأمير عبد الماجد">الأمير عبد الماجد</option>
<option value="الأمير فواز الجنوبي">الأمير فواز الجنوبي</option>
<option value="الأمير فواز الشمالي">الأمير فواز الشمالي</option>
<option value="العمارية">العمارية</option>
<option value="الأمواج">الأمواج</option>
<option value="الأندلس">الأندلس</option>
<option value="الأصالة">الأصالة</option>
<option value="العزيزية">العزيزية</option>
<option value="البغدادية الغربية">البغدادية الغربية</option>
<option value="البغدادية الشرقية">البغدادية الشرقية</option>
<option value="البلد">البلد</option>
<option value="البركة">البركة</option>
<option value="البساتين">البساتين</option>
<option value="البشائر">البشائر</option>
<option value="البوادي">البوادي</option>
<option value="الفاضل">الفاضل</option>
<option value="الفضيلة">الفضيلة</option>
<option value="الفيحاء">الفيحاء</option>
<option value="الفيصلية">الفيصلية</option>
<option value="الفلاح">الفلاح</option>
<option value="الفاروق">الفاروق</option>
<option value="الفردوس">الفردوس</option>
<option value="الهدا">الهدا</option>
<option value="الحمدانية">الحمدانية</option>
<option value="الحمرا">الحمرا</option>
<option value="الهنداوية">الهنداوية</option>
<option value="الجامعة">الجامعة</option>
<option value="الجوهرة">الجوهرة</option>
<option value="الكندرة">الكندرة</option>
<option value="الكرامة">الكرامة</option>
<option value="الكوثر">الكوثر</option>
<option value="الخالدية">الخالدية</option>
<option value="الخمرة">الخمرة</option>
<option value="اللؤلؤة">اللؤلؤة</option>
<option value="المحجر">المحجر</option>
<option value="المنار">المنار</option>
<option value="المروة">المروة</option>
<option value="المسرة">المسرة</option>
<option value="المنتزه">المنتزه</option>
<option value="المليسا">المليسا</option>
<option value="المحمدية">المحمدية</option>
<option value="المرجان">المرجان</option>
<option value="المتنازهات">المتنازهات</option>
<option value="القزين">القزين</option>
<option value="القرية">القرية</option>
<option value="القريات">القريات</option>
<option value="الوادي">الوادي</option>
<option value="الواحة">الواحة</option>
<option value="الوزيرية">الوزيرية</option>
<option value="الورود">الورود</option>
<option value="الياقوت">الياقوت</option>
<option value="النهضة">النهضة</option>
<option value="النعيم">النعيم</option>
<option value="النخيل">النخيل</option>
<option value="النسيم">النسيم</option>
<option value="النزلة اليمانية">النزلة اليمانية</option>
<option value="النزلة الشرقية">النزلة الشرقية</option>
<option value="النزهة">النزهة</option>
<option value="الربوة">الربوة</option>
<option value="الرحمة">الرحمة</option>
<option value="الرحمانية">الرحمانية</option>
<option value="الروابي">الروابي</option>
<option value="الروضة">الروضة</option>
<option value="الريان">الريان</option>
<option value="الرحاب">الرحاب</option>
<option value="مدائن الفهد">مدائن الفهد</option>
<option value="مشرفة">مشرفة</option>
<option value="مريخ">مريخ</option>
<option value="بترومين">بترومين</option>
<option value="طيبة">طيبة</option>
<option value="أم السلام">أم السلام</option>
<option value="أم حبلين">أم حبلين</option>
<option value="مكة المكرمة">مكة المكرمة</option>
<option value="العدل">العدل</option>
<option value="الضيافة">الضيافة</option>
<option value="أجياد">أجياد</option>
<option value="الأندلس">الأندلس</option>
<option value="العوالي">العوالي</option>
<option value="العزيزية">العزيزية</option>
<option value="البيبيان">البيبيان</option>
<option value="البحيرة">البحيرة</option>
<option value="الحجلة">الحجلة</option>
<option value="الحمرا أم الجود">الحمرا أم الجود</option>
<option value="الهرم">الهرم</option>
<option value="الهجرة">الهجرة</option>
<option value="الهنداوية">الهنداوية</option>
<option value="الهجون">الهجون</option>
<option value="الجامعة">الجامعة</option>
<option value="الجميزة">الجميزة</option>
<option value="الكعكية">الكعكية</option>
<option value="الخضراء">الخضراء</option>
<option value="الخالدية">الخالدية</option>
<option value="الخنساء">الخنساء</option>
<option value="المعابدة">المعابدة</option>
<option value="المنصور">المنصور</option>
<option value="المشير">المشير</option>
<option value="المسفلة">المسفلة</option>
<option value="المرسلات">المرسلات</option>
<option value="القرارة والنقاء">القرارة والنقاء</option>
<option value="العقيشة">العقيشة</option>
<option value="العمرة الجديدة">العمرة الجديدة</option>
<option value="العصيلة">العصيلة</option>
<option value="العتيبية">العتيبية</option>
<option value="النسيم">النسيم</option>
<option value="النزهة">النزهة</option>
<option value="الراشدية">الراشدية</option>
<option value="الروابي">الروابي</option>
<option value="الروضة">الروضة</option>
<option value="الرصيفة">الرصيفة</option>
<option value="السلامة">السلامة</option>
<option value="السليمانية">السليمانية</option>
<option value="الشوقية">الشوقية</option>
<option value="الشبيكة">الشبيكة</option>
<option value="الشهداء">الشهداء</option>
<option value="أشاراي">أشاراي</option>
<option value="في طندباوي">في طندباوي</option>
<option value="لدى تانيم">لدى تانيم</option>
<option value="في التقوى">في التقوى</option>
<option value="لدى تيسير">لدى تيسير</option>
<option value="الزاهر">الزاهر</option>
<option value="الزهراء">الزهراء</option>
<option value="بطحاء قريش">بطحاء قريش</option>
<option value="حارة الباب والشامية">حارة الباب والشامية</option>
<option value="جبل النور">جبل النور</option>
<option value="جرهم">جرهم</option>
<option value="جرول">جرول</option>
<option value="الملك فهد">الملك فهد</option>
<option value="كودي">كودي</option>
<option value="راي زخير">راي زخير</option>
<option value="شعيب أمير وشيب علي">شعيب أمير وشيب علي</option>
<option value="شراي المجاهدين">شراي المجاهدين</option>
<option value="وادي جليل">وادي جليل</option>
<option value="والي العهد">والي العهد</option>
<option value="المدينة المنورة">المدينة المنورة</option>
<option value="العقول">العقول</option>
<option value="شوران">شوران</option>
<option value="طيبة">طيبة</option>
<option value="الجرف الغربي">الجرف الغربي</option>
<option value="الجرف الشرقي">الجرف الشرقي</option>
<option value="خاخ">خاخ</option>
<option value="أبو بريقة">أبو بريقة</option>
<option value="أبو كبير">أبو كبير</option>
<option value="الدار">الدار</option>
<option value="أد ديفا">أد ديفا</option>
<option value="الدويخلة">الدويخلة</option>
<option value="الدويمة">الدويمة</option>
<option value="مطار">مطار</option>
<option value="العنابس">العنابس</option>
<option value="العريض">العريض</option>
<option value="العزيزية">العزيزية</option>
<option value="البلقاء">البلقاء</option>
<option value="البركة">البركة</option>
<option value="الفتح">الفتح</option>
<option value="الغبة">الغبة</option>
<option value="الغراء">الغراء</option>
<option value="الحدة">الحدة</option>
<option value="الحضارة">الحضارة</option>
<option value="الحفية">الحفية</option>
<option value="الهرم">الهرم</option>
<option value="الحهن">الحهن</option>
<option value="الاسكان">الاسكان</option>
<option value="الجابيرة">الجابيرة</option>
<option value="الجماعة">الجماعة</option>
<option value="الجامعة">الجامعة</option>
<option value="الجسع">الجسع</option>
<option value="الجمعة">الجمعة</option>
<option value="الخالدية">الخالدية</option>
<option value="الخاتم">الخاتم</option>
<option value="المبوث">المبوث</option>
<option value="المناخ">المناخ</option>
<option value="المساني">المساني</option>
<option value="المغيسلة">المغيسلة</option>
<option value="القلعة">القلعة</option>
<option value="القصوة">القصوة</option>
<option value="القبلتين">القبلتين</option>
<option value="العصيفرين">العصيفرين</option>
<option value="العصبة">العصبة</option>
<option value="العيون">العيون</option>
<option value="الوبرة">الوبرة</option>
<option value="النخيل">النخيل</option>
<option value="النقاء">النقاء</option>
<option value="النقاص">النقاص</option>
<option value="الرنونة">الرنونة</option>
<option value="الروابي">الروابي</option>
<option value="الراية">الراية</option>
<option value="الرومانة">الرومانة</option>
<option value="حزين">حزين</option>
<option value="الصادقية">الصادقية</option>
<option value="الساكب">الساكب</option>
<option value="السلام">السلام</option>
<option value="كما سيه">كما سيه</option>
<option value="سكة الحديد">سكة الحديد</option>
<option value="السقية">السقية</option>
<option value="الشافية">الشافية</option>
<option value="الشهباء">الشهباء</option>
<option value="الشريبات">الشريبات</option>
<option value="عين الكاف">عين الكاف</option>
<option value="الزاهرة">الزاهرة</option>
<option value="الزهراء">الزهراء</option>
<option value="بدعة">بدعة</option>
<option value="بني عبد الأشحل">بني عبد الأشحل</option>
<option value="بني بياضة">بني بياضة</option>
<option value="بني حارثة">بني حارثة</option>
<option value="بني خضرة">بني خضرة</option>
<option value="بني معاوية">بني معاوية</option>
<option value="بني ظفر">بني ظفر</option>
<option value="بير عثمان">بير عثمان</option>
<option value="ذو الحليفة">ذو الحليفة</option>
<option value="هاثم">هاثم</option>
<option value="المنطقة الصناعية">المنطقة الصناعية</option>
<option value="جبل أحد">جبل أحد</option>
<option value="الملك فهد">الملك فهد</option>
<option value="كتانة">كتانة</option>
<option value="مهز">مهز</option>
<option value="مسجد الدار">مسجد الدار</option>
<option value="المذينيب">المذينيب</option>
<option value="نوبالا">نوبالا</option>
<option value="قلعة مخيت">قلعة مخيت</option>
<option value="الأضاحي">الأضاحي</option>
<option value="رهط">رهط</option>
<option value="سيد الشهداء">سيد الشهداء</option>
<option value="شيزات">شيزات</option>
<option value="ورقة">ورقة</option>
<option value="الناهي">الناهي</option>
<option value="محايل عسير">محايل عسير</option>
<option value="وادي الدواسر">وادي الدواسر</option>
<option value="الظهران الجنوب">الظهران الجنوب</option>
<option value="بني كبير">بني كبير</option>
<option value="أبو عريش">أبو عريش</option>
<option value="صامطة">صامطة</option>
<option value="صبيا">صبيا</option>
<option value="فيفا">فيفا</option>
<option value="أحد المصاريحة">أحد المصاريحة</option>
<option value="وادي جازان">وادي جازان</option>
<option value="عسير">عسير</option>
<option value="تريب">تريب</option>
<option value="بلسمر">بلسمر</option>
<option value="فرسان">فرسان</option>
<option value="الطوال">الطوال</option>
<option value="مسام">مسام</option>
<option value="داماد">داماد</option>
<option value="العارضة">العارضة</option>
<option value="الدارب">الدارب</option>
<option value="الخوبة">الخوبة</option>
<option value="شرورة">شرورة</option>
<option value="الحرجة">الحرجة</option>
<option value="الخرخير">الخرخير</option>
<option value="يدمة">يدمة</option>
<option value="هوبونا">هوبونا</option>
<option value="بدر الجنوب">بدر الجنوب</option>
<option value="الإدابي">الإدابي</option>
<option value="الشقيق">الشقيق</option>
<option value="سبت العليا">سبت العليا</option>
<option value="الباحة">الباحة</option>
<option value="الشروق">الشروق</option>
<option value="الضباب">الضباب</option>
<option value="الأزهر">الأزهر</option>
<option value="البحر">البحر</option>
<option value="بريدة">بريدة</option>
<option value="الحجاز">الحجاز</option>
<option value="المرتفة">المرتفة</option>
<option value="النسيم">النسيم</option>
<option value="النزهة">النزهة</option>
<option value="الربوة">الربوة</option>
<option value="الروضة">الروضة</option>
<option value="الصفا">الصفا</option>
<option value="السلامة">السلامة</option>
<option value="الشفا">الشفا</option>
<option value="في تافير">في تافير</option>
<option value="شهبة">شهبة</option>
<option value="رجال ألمع">رجال ألمع</option>
<option value="تنومة">تنومة</option>
<option value="سراة عبيدة">سراة عبيدة</option>
<option value="تثليث">تثليث</option>
<option value="النماص">النماص</option>
<option value="الربواح">الربواح</option>
<option value="خميس مشيط">خميس مشيط</option>
<option value="إسكان اللواء 14">إسكان اللواء 14</option>
<option value="الدوحة">الدوحة</option>
<option value="إسكان القوات الجوية">إسكان القوات الجوية</option>
<option value="العزيزية">العزيزية</option>
<option value="البوادي">البوادي</option>
<option value="الفتح">الفتح</option>
<option value="الحرير">الحرير</option>
<option value="الحميلة">الحميلة</option>
<option value="العرق الجنوبي">العرق الجنوبي</option>
<option value="العرق الشمالي">العرق الشمالي</option>
<option value="الجزيرة">الجزيرة</option>
<option value="الخالدية">الخالدية</option>
<option value="الخزان">الخزان</option>
<option value="المعمورة">المعمورة</option>
<option value="المثنى">المثنى</option>
<option value="الميزاب">الميزاب</option>
<option value="المنتزه">المنتزه</option>
<option value="تقسيم الموسى">تقسيم الموسى</option>
<option value="القافلة">القافلة</option>
<option value="الواحة">الواحة</option>
<option value="النهضة">النهضة</option>
<option value="النخيل">النخيل</option>
<option value="النسيم">النسيم</option>
<option value="النزهة">النزهة</option>
<option value="الربيع">الربيع</option>
<option value="الرسرات">الرسرات</option>
<option value="الروضة">الروضة</option>
<option value="الرونة">الرونة</option>
<option value="حزين">حزين</option>
<option value="السلام">السلام</option>
<option value="كما صقور">كما صقور</option>
<option value="الشرفية">الشرفية</option>
<option value="الشفا">الشفا</option>
<option value="عتود">عتود</option>
<option value="الزهور">الزهور</option>
<option value="داماك">داماك</option>
<option value="دلالة">دلالة</option>
<option value="إسكان المدينة العسكرية">إسكان المدينة العسكرية</option>
<option value="قمبر">قمبر</option>
<option value="شاكار">شاكار</option>
<option value="الشباع">الشباع</option>
<option value="الطيب العسم">الطيب العسم</option>
<option value="أم سرور">أم سرور</option>
<option value="أبها">أبها</option>
<option value="أحد رفيدة">أحد رفيدة</option>
<option value="جازان">جازان</option>
<option value="نجران">نجران</option>
<option value="تقسيم الصهبان">تقسيم الصهبان</option>
<option value="أبا لسعود">أبا لسعود</option>
<option value="الضباط">الضباط</option>
<option value="الأمير مشعل">الأمير مشعل</option>
<option value="الأمانة">الأمانة</option>
<option value="التقسيمات الفرعية العريسة الجنوبية">التقسيمات الفرعية العريسة الجنوبية</option>
<option value="تقسيم العريسة الشمالي">تقسيم العريسة الشمالي</option>
<option value="العذيبة الشمالية">العذيبة الشمالية</option>
<option value="الفهد">الفهد</option>
<option value="الفيصلية">الفيصلية</option>
<option value="التقسيمات الزراعية في الغويلة">التقسيمات الزراعية في الغويلة</option>
<option value="الحدن">الحدن</option>
<option value="التقسيم الزراعي">التقسيم الزراعي</option>
<option value="الجربة">الجربة</option>
<option value="الخالدية">الخالدية</option>
<option value="الخيام">الخيام</option>
<option value="القابل">القابل</option>
<option value="الدحداح">الدحداح</option>
<option value="تقسيم ابن جار الله السكني">تقسيم ابن جار الله السكني</option>
<option value="تقسيمات نحوفة الزراعية">تقسيمات نحوفة الزراعية</option>
<option value="تقسيم نحوفة السكني">تقسيم نحوفة السكني</option>
<option value="مطار نجران الإقليمي">مطار نجران الإقليمي</option>
<option value="رجلاء الجنوبية">رجلاء الجنوبية</option>
<option value="رجلاء الشمالية">رجلاء الشمالية</option>
<option value="التقسيم السكني شايب رير">التقسيم السكني شايب رير</option>
<option value="شمال الضباط">شمال الضباط</option>
<option value="شمال الفهد">شمال الفهد</option>
<option value="شرق الضباط">شرق الضباط</option>
<option value="المجريدة">المجريدة</option>
<option value="الشيخ">الشيخ</option>
<option value="بيشة">بيشة</option>
<option value="قلوة">قلوة</option>
<option value="حبونا">حبونا</option>
<option value="الغاط">الغاط</option>
<option value="دارما">دارما</option>
<option value="ثاديك">ثاديك</option>
<option value="مارات">مارات</option>
<option value="روضة سدير">روضة سدير</option>
<option value="الدرعية">الدرعية</option>
<option value="الزلفي">الزلفي</option>
<option value="الدوادمي">الدوادمي</option>
<option value="الأفلاج">الأفلاج</option>
<option value="حططة بني تميم">حططة بني تميم</option>
<option value="الجلاجل">الجلاجل</option>
<option value="الدلم">الدلم</option>
<option value="الحارق">الحارق</option>
<option value="السليل">السليل</option>
<option value="المزاحمية">المزاحمية</option>
<option value="تعمير">تعمير</option>
<option value="عفيف">عفيف</option>
<option value="ساجر">ساجر</option>
<option value="رماح">رماح</option>
<option value="البجادية">البجادية</option>
<option value="الحلوة">الحلوة</option>
<option value="عينيا وجبيلية">عينيا وجبيلية</option>
<option value="القويعية">القويعية</option>
<option value="هوتيت سدير">هوتيت سدير</option>
<option value="الحياة">الحياة</option>
<option value="الرين">الرين</option>
<option value="الرويضة">الرويضة</option>
<option value="نافع">نافع</option>
<option value="القصب">القصب</option>
<option value="الهدار">الهدار</option>
<option value="الأرطاوية">الأرطاوية</option>
<option value="الجمش">الجمش</option>
<option value="جابا">جابا</option>
<option value="بكاء">بكاء</option>
<option value="الروضة">الروضة</option>
<option value="موكيك">موكيك</option>
<option value="هيت">هيت</option>
<option value="الكحفة">الكحفة</option>
<option value="الشاملي">الشاملي</option>
<option value="البكيرية">البكيرية</option>
<option value="البدائع">البدائع</option>
<option value="رياض الخبر">رياض الخبر</option>
<option value="عيون الجوا">عيون الجوا</option>
<option value="الرفايع">الرفايع</option>
<option value="جفاير">جفاير</option>
<option value="يبرين">يبرين</option>
<option value="الأمل">الأمل</option>
<option value="دار البيضاء">دار البيضاء</option>
<option value="أد ديفا">أد ديفا</option>
<option value="الديراء">الديراء</option>
<option value="الضبية">الضبية</option>
<option value="الدريهمية">الدريهمية</option>
<option value="الضباط">الضباط</option>
<option value="الأندلس">الأندلس</option>
<option value="العقيق">العقيق</option>
<option value="العارض">العارض</option>
<option value="العزيزية">العزيزية</option>
<option value="البادية">البادية</option>
<option value="البارية">البارية</option>
<option value="البطحاء">البطحاء</option>
<option value="آل دو">آل دو</option>
<option value="الفيحاء">الفيحاء</option>
<option value="الفيصلية">الفيصلية</option>
<option value="الفاخرية">الفاخرية</option>
<option value="الفلاح">الفلاح</option>
<option value="الفاروق">الفاروق</option>
<option value="الفوطة">الفوطة</option>
<option value="الغدير">الغدير</option>
<option value="الغنامية">الغنامية</option>
<option value="الهدا">الهدا</option>
<option value="الحائر">الحائر</option>
<option value="الحمرا">الحمرا</option>
<option value="الحزم">الحزم</option>
<option value="الإمام محمد بن سعود">الإمام محمد بن سعود</option>
<option value="الاسكان">الاسكان</option>
<option value="الازدهار">الازدهار</option>
<option value="الجنادرية">الجنادرية</option>
<option value="الجرادية">الجرادية</option>
<option value="الجزيرة">الجزيرة</option>
<option value="الخالدية">الخالدية</option>
<option value="الخليج">الخليج</option>
<option value="الخزامى">الخزامى</option>
<option value="المعذر">المعذر</option>
<option value="المضر الشمالي">المضر الشمالي</option>
<option value="المهدية">المهدية</option>
<option value="المايزلة">المايزلة</option>
<option value="الملز">الملز</option>
<option value="الملقا">الملقا</option>
<option value="المناخ">المناخ</option>
<option value="المنار">المنار</option>
<option value="المنصورة">المنصورة</option>
<option value="المرقب">المرقب</option>
<option value="المروة">المروة</option>
<option value="المساني">المساني</option>
<option value="المصيف">المصيف</option>
<option value="المكل">المكل</option>
<option value="المسفاة">المسفاة</option>
<option value="المشعل">المشعل</option>
<option value="المغريزات">المغريزات</option>
<option value="المحمدية">المحمدية</option>
<option value="المونسية">المونسية</option>
<option value="المربع">المربع</option>
<option value="المرسلات">المرسلات</option>
<option value="المروج">المروج</option>
<option value="المؤتمرات">المؤتمرات</option>
<option value="القادسية">القادسية</option>
<option value="القيروان">القيروان</option>
<option value="القدس">القدس</option>
<option value="القرى">القرى</option>
<option value="الشهداء">الشهداء</option>
<option value="العود">العود</option>
<option value="العليا">العليا</option>
<option value="الأمجيا">الأمجيا</option>
<option value="العريجة">العريجة</option>
<option value="العريجة الغرب">العريجة الغرب</option>
<option value="العريجة الوسطى">العريجة الوسطى</option>
<option value="الوادي">الوادي</option>
<option value="الواحة">الواحة</option>
<option value="الوزارات">الوزارات</option>
<option value="الوشام">الوشام</option>
<option value="الورود">الورود</option>
<option value="الوسيطة">الوسيطة</option>
<option value="اليمامة">اليمامة</option>
<option value="اليرموك">اليرموك</option>
<option value="الياسمين">الياسمين</option>
<option value="الندى">الندى</option>
<option value="و نافل">و نافل</option>
<option value="النهضة">النهضة</option>
<option value="النخيل">النخيل</option>
<option value="النماوذجية">النماوذجية</option>
<option value="النرجس">النرجس</option>
<option value="النسيم الغربي">النسيم الغربي</option>
<option value="النسيم الشرقي">النسيم الشرقي</option>
<option value="الناصرية">الناصرية</option>
<option value="الناظم">الناظم</option>
<option value="النور">النور</option>
<option value="النزهة">النزهة</option>
<option value="الربيع">الربيع</option>
<option value="الربوة">الربوة</option>
<option value="الرفيعة">الرفيعة</option>
<option value="الرحمانية">الرحمانية</option>
<option value="غارة">غارة</option>
<option value="الروابي">الروابي</option>
<option value="الروضة">الروضة</option>
<option value="الريان">الريان</option>
<option value="الرمال">الرمال</option>
<option value="الرماية">الرماية</option>
<option value="العريض">العريض</option>
<option value="السعادة">السعادة</option>
<option value="الصفا">الصفا</option>
<option value="السفارات">السفارات</option>
<option value="الصحاف">الصحاف</option>
<option value="السلام">السلام</option>
<option value="الصالحية">الصالحية</option>
<option value="الصناعية">الصناعية</option>
<option value="السلي">السلي</option>
<option value="السليمانية">السليمانية</option>
<option value="السويدي">السويدي</option>
<option value="السويدي الغربي">السويدي الغربي</option>
<option value="الشرفية">الشرفية</option>
<option value="الشرق">الشرق</option>
<option value="الشفا">الشفا</option>
<option value="الشميسي">الشميسي</option>
<option value="في التعاون">في التعاون</option>
<option value="الزهراء">الزهراء</option>
<option value="بدر">بدر</option>
<option value="بنبان">بنبان</option>
<option value="ضاحية نمار">ضاحية نمار</option>
<option value="ظهرة البديعة">ظهرة البديعة</option>
<option value="ظهرة لبن">ظهرة لبن</option>
<option value="ظهرة نمار">ظهرة نمار</option>
<option value="ديراب">ديراب</option>
<option value="غرناطة">غرناطة</option>
<option value="الغبيرة">الغبيرة</option>
<option value="حطين">حطين</option>
<option value="هيت">هيت</option>
<option value="عائشة">عائشة</option>
<option value="عرقة">عرقة</option>
<option value="اشبيلية">اشبيلية</option>
<option value="جبرة">جبرة</option>
<option value="جرير">جرير</option>
<option value="خشم العان">خشم العان</option>
<option value="الملك عبد العزيز">الملك عبد العزيز</option>
<option value="الملك عبدالله">الملك عبدالله</option>
<option value="الملك فهد">الملك فهد</option>
<option value="الملك فيصل">الملك فيصل</option>
<option value="مطار الملك خالد">مطار الملك خالد</option>
<option value="جامعة الملك سعود">جامعة الملك سعود</option>
<option value="لبن">لبن</option>
<option value="منفوحة">منفوحة</option>
<option value="منفوحة الجديدة">منفوحة الجديدة</option>
<option value="المنصورية">المنصورية</option>
<option value="نمار">نمار</option>
<option value="قرطبة">قرطبة</option>
<option value="صلاح الدين">صلاح الدين</option>
<option value="المدينة الصناعية الثانية">المدينة الصناعية الثانية</option>
<option value="شبرا">شبرا</option>
<option value="سياه">سياه</option>
<option value="سكيرينا">سكيرينا</option>
<option value="سلطانة">سلطانة</option>
<option value="طيبة">طيبة</option>
<option value="الثليم">الثليم</option>
<option value="طويق">طويق</option>
<option value="أحد">أحد</option>
<option value="أم الشال">أم الشال</option>
<option value="أم الحمام الغربي">أم الحمام الغربي</option>
<option value="أم الحمام الشرقي">أم الحمام الشرقي</option>
<option value="أم سليم">أم سليم</option>
<option value="عكاظ">عكاظ</option>
<option value="عتيقة">عتيقة</option>
<option value="الفواز">الفواز</option>
<option value="شقراء">شقراء</option>
<option value="روضة سدير">روضة سدير</option>
<option value="قلعة الصقور">قلعة الصقور</option>
<option value="الخرج">الخرج</option>
<option value="هوريملا">هوريملا</option>
<option value="الجوف">الجوف</option>
<option value="بريدة">بريدة</option>
<option value="في تشليح">في تشليح</option>
<option value="الضاحي">الضاحي</option>
<option value="الدعيسة">الدعيسة</option>
<option value="الأخضر">الأخضر</option>
<option value="الأمن">الأمن</option>
<option value="البصر">البصر</option>
<option value="البشر">البشر</option>
<option value="البريكة">البريكة</option>
<option value="الفاخرية">الفاخرية</option>
<option value="الفيزية">الفيزية</option>
<option value="الغماس">الغماس</option>
<option value="الحمر">الحمر</option>
<option value="الحق الأخضر">الحق الأخضر</option>
<option value="الحساتات">الحساتات</option>
<option value="الهلال">الهلال</option>
<option value="الحويقية">الحويقية</option>
<option value="الاسكان">الاسكان</option>
<option value="الجامعين">الجامعين</option>
<option value="الجردة">الجردة</option>
<option value="الخضر ووجيان">الخضر ووجيان</option>
<option value="الخالدية">الخالدية</option>
<option value="الخليج">الخليج</option>
<option value="الخبيبية">الخبيبية</option>
<option value="اللسيب">اللسيب</option>
<option value="الملك">الملك</option>
<option value="الموطة">الموطة</option>
<option value="آل مزلال">آل مزلال</option>
<option value="المنتزه">المنتزه</option>
<option value="المنتزه الجنوبي">المنتزه الجنوبي</option>
<option value="عنيزة">عنيزة</option>
<option value="حطين">حطين</option>
<option value="الذهبي">الذهبي</option>
<option value="الدوحة">الدوحة</option>
<option value="مطار">مطار</option>
<option value="العبسية">العبسية</option>
<option value="العقيق">العقيق</option>
<option value="الأشرفية">الأشرفية</option>
<option value="العوينية">العوينية</option>
<option value="البادية">البادية</option>
<option value="البويطن">البويطن</option>
<option value="البستان">البستان</option>
<option value="الفيحاء">الفيحاء</option>
<option value="الفيصلية">الفيصلية</option>
<option value="الفقامة">الفقامة</option>
<option value="الهداء">الهداء</option>
<option value="الحدة">الحدة</option>
<option value="الحاجب">الحاجب</option>
<option value="الحمرا">الحمرا</option>
<option value="الحزم">الحزم</option>
<option value="الحفيرة">الحفيرة</option>
<option value="الاسكان">الاسكان</option>
<option value="الجلدة">الجلدة</option>
<option value="الجامعي">الجامعي</option>
<option value="الجوهرة">الجوهرة</option>
<option value="الجزيرة">الجزيرة</option>
<option value="القعدة">القعدة</option>
<option value="الخالدية">الخالدية</option>
<option value="الخليج">الخليج</option>
<option value="الرس">الرس</option>
<option value="النوارية">النوارية</option>
<option value="خبرا">خبرا</option>
<option value="النبهانية">النبهانية</option>
<option value="القريات">القريات</option>
<option value="الجمش">الجمش</option>
<option value="الأسياح">الأسياح</option>
<option value="الشماسية">الشماسية</option>
<option value="المذنب">المذنب</option>
                      </select>
                    </div>
               
                    <div className="form-row">
                      <div className="col" id="message">
                        <label>الموضوع</label>
                        <textarea
                          className="form-control"
                          type="text"
                          name="interestedIn"
                          placeholder="الموضوع"
                          value={formValues.interestedIn}
                          onChange={this.handleUserInterestedInChange} />
                        {formErrors.interestedIn && <p style={{ color: 'red' }}>{formErrors.interestedIn}</p>}

                      </div>
                    </div>
                    <div className="form-button">
                      <button className="ibtn extra-padding" id="btncolor" type="submit"> أرسال </button>
                    </div>

                  </form>
                  </div>
                  )}


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default ArForm